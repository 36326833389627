import { render, staticRenderFns } from "./previewLoader.vue?vue&type=template&id=78396716&scoped=true&"
var script = {}
import style0 from "./previewLoader.vue?vue&type=style&index=0&id=78396716&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "78396716",
  null
  
)

export default component.exports