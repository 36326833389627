const state = {
    statuses: [
        // { name: 'Всі типи', id: null },
        { name: 'Чернетка', id: 0 },
        { name: 'Опублікований', id: 1 },
        // { name: '3', id: 3 },
    ],
};

const getters = {
    KNOWLEDGE_BASE_STATUSES: state => state.statuses
};

export default {
    state,
    getters
};
