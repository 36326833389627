<template>
    <div class="form-group">
    <multiselect
        class="choice_city params-city-status"
        :class="{'form-control' : true, 'is-invalid' : isInvalid(this.city)}"
        v-model="city"
        :options="citiesOptions"
        placeholder="Введіть назву міста"
        @search-change="searchCities"
        :internal-search="false"
        :max-height="165"
        :options-limit="300"
        :allow-empty="false"
        label="name_full_name_ua">
    </multiselect>
    <span :class="['popular-city', {'is-invalid' : euroChecked}]" v-if="popularCitiess" v-for="(item, index) in popularCitiess">
        <a href="#" @click.prevent="searchCities(item.name_full_name_ua); choseCity(item)"
            class="link"
            v-text="item.name+ ', '"></a>
    </span>
    <span v-if="euroToggle" class="float-right insurance__euro-toggle" data-toggle="tooltip" data-placement="bottom" :data-original-title="otherCountry.name">
        <input type="checkbox" id="euroCheckedParams" v-model="euroChecked" @change="euroStatus(otherCountry);">
        <label for="euroCheckedParams">Євро номер</label>
    </span>
    </div>
</template>

<script>
    import Multiselect from 'vue-multiselect'
    import * as router from "vue-router";

    export default {
        components: {Multiselect},

        props: ['euroToggle', 'popularCities', 'cityStore', 'citySelect', 'sendEuroStatus'],

        data: function () {
            return {
                city: {"id": 1,"name": "Київ","name_full_name_ua": "м. Київ, Україна", "zone": 1},
                euroChecked: false,
                cities: [],
                citiesOptions: [ {"id": 1,"name": "Київ","name_full_name_ua": "м. Київ, Україна", "zone":1},
                  {"id": 9,"name": "Дніпро","name_full_name_ua": "м. Дніпро, Дніпропетровська  обл., Україна", "zone":2},
                  {"id": 11,"name": "Львів","name_full_name_ua": "м. Львів, Львівська  обл., Україна", "zone":1},
                  {"id": 13,"name": "Одеса","name_full_name_ua": "м. Одеса, Одеська  обл., Україна", "zone":2},
                  {"id": 14,"name": "Харків","name_full_name_ua": "м. Харків, Харківська  обл., Україна", "zone":2},
                  {"id": 16,"name": "Запоріжжя","name_full_name_ua": "м. Запоріжжя, Запорізька  обл., Україна", "zone":3},
                  {"id": 17,"name": "Кривий Ріг","name_full_name_ua": "м. Кривий Ріг, Дніпропетровська  обл., Україна", "zone":3},
                  {"id": 21,"name": "Вінниця","name_full_name_ua": "м. Вінниця, Вінницька  обл., Україна", "zone":4},
                  {"id": 36,"name": "Маріуполь","name_full_name_ua": "м. Маріуполь, Донецька  обл., Україна", "zone":4},
                  {"id": 41,"name": "Полтава","name_full_name_ua": "м. Полтава, Полтавська  обл., Україна", "zone":4}],
                popularCitiess: [],
                isLoading: false,
            };
        },
        watch: {
            cityStore: function (newValue) {
              this.city = newValue;
            },
            city: function (newValue) {
                this.citySelected();
            },

            sendEuroStatus: function (newVal, oldVal){
                if(this.sendEuroStatus == true){
                    this.euroChecked = true;
                    this.euroStatus(this.otherCountry);
                }else{
                    this.euroChecked = false;
                    this.euroStatus();
                }
            },
        },

        created() {
            this.citySelected();

            if(this.cityStore){
                this.city = this.cityStore;
            }
            if (this.popularCities) {
                this.popularCitiess = this.popularCities;
            }
        },

        computed: {
            otherCity: function () {
                let name = 'Інші населені пункти України';

                let results = this.cities.filter((city) => {
                    return name.includes(city.name_full_name_ua);
                });

                if(results.length === 0) {
                    // 29751 ид другого населенного пункта. Вынести в словарь.
                    return {
                        id: 29751,
                        name: name,
                        name_full_name_ua: name,
                        zone: 5,
                    };
                }

                return results[0];
            },

            otherCountry: function () {
                let name = 'ТС зареєстровано в іншій країні';

                let results = this.cities.filter((city) => {
                    return name.includes(city.name_full_name_ua);
                });
                if(results.length === 0) {
                    return {
                        name_full_name_ua: name,
                        zone:0,
                        id: 0
                    };
                }

                return results[0];
            },
        },

        methods: {
            searchCities(query) {
                if(query.length > 1){
                    this.fetchCities(query);
                }
            },

            fetchCities(query) {
                axios.get('/api/directories/cities/find?city=' + query).then(({data}) => {
                    this.cities = data.data;
                    this.citiesOptions = data.data;

                    if(this.citiesOptions.length === 0) {
                        this.citiesOptions.push(this.otherCity);
                    }
                }).catch(err => (console.log(err)));
            },

            euroStatus(value){
                $('[data-toggle="tooltip"]').tooltip('hide');
                if(this.euroChecked === true){
                    this.city = value;
                    $('.params-city-status').addClass('disabled');
                }else{
                    this.city = null;
                    $('.params-city-status').removeClass('disabled');
                }
            },
            choseCity(value) {
                this.city = value;
                $(".tooltip").remove();
                this.euroChecked = false;
                $('.params-city-status').removeClass('disabled');
            },

            isInvalid(val) {
                return val == null && this.submitForm;
            },

            citySelected () {
                this.citySelect({
                    city: this.city,
                })
            },
        }
    }
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
