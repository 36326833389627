<template>
    <div class="container news-container categories-page">
            <div v-if="condition.isLoadArticles" class="row">
                <preview-loader v-for="index in 3" :key="index"></preview-loader>
            </div>
        <div class="row" v-else-if="knowledgeBaseCategories && knowledgeBaseCategories.length > 0">
            <div class="row">
                <div class="col-lg-4 category-item" v-for="category in knowledgeBaseCategories">
                    <div class="category-head">
                        <div class="category-icon"><img :src="category.categoryImageUrl"/></div>
                        <a class="category-name" :href="'/knowledge-base-categories/' + category.id">
                            {{ category.name }}
                        </a>
                    </div>
                    <div class="category-articles" >
                        <div class="articles-link" v-for="article in category.knowledgeBaseArticles">
                            <a :href="'/knowledge-base-articles/' + article.id">
                                {{ article.title }}
                            </a>
                        </div>
                        <div class="" v-if="category.knowledgeBaseArticles.length > 4">
                            <a :href="'/knowledge-base-categories/' + category.id">
                                Еще статей {{ category.knowledgeBaseArticles.length - shownArticlesCount }}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="empty-notification__text">
            <p>Тут будуть з`являтися новини</p>
        </div>
<!--        <div style="text-align: center">-->
<!--            <pagination v-show="total > 0" :total="total"-->
<!--                        :page.sync="listQuery.page"-->
<!--                        :limit.sync="listQuery.limit" @pagination="initList()" />-->
<!--        </div>-->
    </div>
</template>

<script>

import Pagination from '@/components/Pagination';
import preview from './components/preview';
import { list as articleList, categoriesList } from '@/api/knowledgeBase';
import { userViewedNews } from '@/api/news';
import previewLoader from './components/previewLoader';

export default {
    components: {
        previewLoader,
        preview,
        Pagination
    },
    data: function () {
        return {
            total: 10,
            condition: {
                isLoadArticles: true,
            },
            listQuery: {
                page: 1,
                limit: 10,
            },
            knowledgeBaseCategories: {},
            shownArticlesCount: 4,
        }
    },
    created:  function() {
        categoriesList(this.listQuery).then((response) => {
            this.knowledgeBaseCategories = response.data?.data?.items ?? [];
            this.total = response.data?.data?.total;
            this.condition.isLoadArticles = false;
        });
    },
    mounted () {
      window.scrollTo(0, 0)
    },
    methods: {
        initList: function () {
            categoriesList(this.listQuery).then((response) => {
                this.knowledgeBaseCategories = response.data?.data?.items ?? [];
                this.total = response.data?.data?.total;
                this.condition.isLoadArticles = false;
            });
        }
    }
}
</script>



<style lang="scss" scoped>
.category-item {
    padding: 25px 0;
    .category-head {
        display: flex;
        align-items: center;
    }
    .category-name{
        font-size: 16px;
    }
    .category-icon {
        margin-right: 10px;
        img {
            width: 24px;
            height: 24px;
        }
    }
    .category-articles {
        margin: 15px 15px 15px 0;
        .articles-link a{
            color: #000;
        }
    }
}


.date {
    font-size: 18px;
}
</style>
