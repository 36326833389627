<template>
   <span>
      <template>
        <p v-for="(file, key) in filteredFiles" :key="key" class="option">
             <span :key="key">
            <span class="icon">
              <svg style="margin-bottom: 7px;" width="16" height="16"
                   viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M14 10V12.6667C14 13.0203 13.8595 13.3594 13.6095 13.6095C13.3594 13.8595 13.0203 14 12.6667 14H3.33333C2.97971 14 2.64057 13.8595 2.39052 13.6095C2.14048 13.3594 2 13.0203 2 12.6667V10"
                    stroke="#24282C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M4.6665 6.66666L7.99984 9.99999L11.3332 6.66666"
                      stroke="#24282C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M8 10V2" stroke="#24282C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </span>
            <a @click="downloadFile(file)" href="javascript:void(0);">
              <span class="text">{{ menuType[key] }}</span>
            </a>
          </span>
        </p>
      </template>
   </span>
</template>

<script>
import {downloadContract} from "@/api/order";

export default {
    name: 'DynamicDownloadContractMenu',
    props: ['dataContractExtraFiles', 'dataOrderType'],
    data: function () {
        return {
            contractExtraFiles: null,
            productType: {
                "dgo": ["contractDgoFile", "protokolDgoFile", "sertDgoFile"],
                "osago": ["contractFile", "protokolPolicyFile", "sertPolicyFile"],
                "autoLayer": ["contractAutoLayerFile"]
            },
            menuType: {
                "contractDgoFile": "Скачати ДЦВ",
                "sertDgoFile": "Скачати сертифікат",
                "protokolDgoFile": "Скачати протокол",
                "contractAutoLayerFile": "Скачати договор автоюриста",
                "infoFile": "Інформаційна довідка",
                "protocolFile": "Завантажити протокол",
                "sertFile": "Завантажити сертифікат",
                "polisFile": "Завантажити поліс",
                "sertPolicyFile": "Скачати сертифікат",
                "protokolPolicyFile": "Скачати протокол",
                "contractFile": "Скачати договір",
            }
        };
    },
    created: function () {
    },
    watch: {},
    computed: {
        dynamicMenu() {
            const menu = {};

            // Перебираем файлы и создаем элементы меню
            for (const [key, value] of Object.entries(this.contractExtraFiles)) {
                if (this.menuType[key]) {
                    menu[this.menuType[key]] = value;
                }
            }

            return menu;
        },
        filteredFiles() {
            if (parseInt(this.dataOrderType) === 3) {
                return this.dataContractExtraFiles;
            }
            // Определяем список допустимых ключей на основе dataOrderType
            const allowedKeys = parseInt(this.dataOrderType) === 4
                ? this.productType.dgo
                : parseInt(this.dataOrderType) === 1
                    ? this.productType.osago
                    : [];

            console.log('this.dataOrderType', this.dataOrderType);
            console.log('this.dataContractExtraFiles', this.dataContractExtraFiles);
            console.log('allowedKeys', allowedKeys);
            // Фильтруем только те ключи, которые присутствуют в allowedKeys
            return Object.fromEntries(
                Object.entries(this.dataContractExtraFiles).filter(([key]) => allowedKeys.includes(key))
            );
        }
    },
    methods: {
        downloadFile(file) {
            window.open(file, '_blank');
        }
    }
}
</script>
<style>
.more-options .option {
    margin-top: 10px;
    margin-left: 21px;
}

.more-options .option a .text {
    height: 21px;
    margin-left: 5px;
}
</style>
