const state = {
    prolongStatuses: [
        { name: '', id: 0 },
        { id: 1, name: 'Нагадано клієнту'},
        { id: 2, name: 'Продав авто'},
        { id: 3, name: 'Оформив в інших'},
        { id: 4, name: 'Немає зв’язку'},
        { id: 5, name: 'Очікує рішення'},
        { id: 6, name: 'Зацікавлений'},
        { id: 7, name: 'Втрачені', hidden: true, statusLost: 1, },
    ],
};

const getters = {
    PROLONG_STATUSES: state => state.prolongStatuses
};

export default {
    state,
    getters
};
