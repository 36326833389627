<template>
    <div class="container knowledge-base-filters" style="margin-bottom: 30px;">
        <div class="insurance-history__menu__filters" ></div>
        <transition name="fade">
            <div class="menu filters mt-4">
                <div class="row">
                    <div class="col-lg-9 m-auto ">
                        <div class="row filters-wrap m-auto">
                            <div class="kb-filters-title">
                                <div class="title-lg">Шукаєте потрібну інформацію на платформі?</div>
                                <div class="title-sm">
                                    Виконайте пошук серед бази знань, щоб отримати відповіді
                                </div>
                            </div>
                            <div class="filters-form-box">
                                <div class="filters-form form-group col-sm-auto m-auto">
                                    <div class="input-group d-flex">
                                        <input class="form-control kb-input" v-model="listQuery.searchText" placeholder="Введіть інформацію для пошуку"/>
<!--                                        <el-select clearable v-model="listQuery.statusId"-->
<!--                                                   class="form-control-el kb-select"-->
<!--                                                   placeholder="Всі статті">-->
<!--                                            <el-option-->
<!--                                                       key="all"-->
<!--                                                       label="Всі статті"-->
<!--                                                       :value="undefined">-->
<!--                                            </el-option>-->
<!--                                            <el-option v-for="item in knowledgeBaseArticlesStatuses"-->
<!--                                                       :key="item.id"-->
<!--                                                       :label="item.name"-->
<!--                                                       :value="item.id">-->
<!--                                            </el-option>-->
<!--                                        </el-select>-->

                                        <el-select clearable v-model="listQuery.categoryId"
                                                   class="form-control-el kb-select"
                                                   placeholder="Всі статті">
                                            <el-option
                                                key="all"
                                                label="Всі статті"
                                                :value="undefined">
                                            </el-option>
                                            <el-option v-for="item in categoriesList"
                                                       :key="item.id"
                                                       :label="item.name"
                                                       :value="item.id">
                                            </el-option>
                                        </el-select>
                                    </div>
                                    <div class="form-group">
                                        <button @click="getList()" class="btn btn-primary btn-find">
                                            Пошук
                                        </button>
                                    </div>
                                </div>
                                <div class="col-sm-auto m-auto top-queries">
                                    <div class="top-queries-label">Популярні пошуки:</div>
                                    <div class="top-queries-items">
                                        <div @click="tapQuery(query)" :class="['top-queries-item', {'active' : isActiveQuery(query)}]" v-for="query in topSearchQueries">
                                            {{ query.searchName }}
                                        </div>
                                    </div>
                                    <div class="top-queries-right"></div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div class="row"></div>
        </div>
        </transition>


    </div>
</template>

<script>
    import Multiselect from 'vue-multiselect';
    import { userMixin, partnerMixin, companyMixin, orderMixin } from '@/mixin'
    import {list as listCompanies} from "@/api/company";
    export default {
        mixins: [userMixin, partnerMixin, companyMixin, orderMixin],
        name: 'FilterBlock',
        props: ['isLoadXml', 'isLoadData', 'articles', 'categoriesList', 'topSearchQueries'],
        components: {Multiselect,},
        data: function () {
            return {
                dateFilter: new Date(),
                condition: {
                    isShowBlock: false,
                    isShowFilter: false,
                    isShowChildrenUser:false
                },
                listQuery: {
                    articleId: null,
                    searchText: null,
                    categoryId: null,
                    categoryName: null,
                    statusId: undefined,
                },
                pickerOptions: {
                    disabledDate(time) {
                        return time.getTime() > Date.now();
                    },
                },
                tagValue: '',
                selectedTag: null,
                foundTags: [],
                tagListLoading: false,
            }
        },
        created() {
            const oneWeekAgo = new Date();
            oneWeekAgo.setDate(oneWeekAgo.getDate() - 2);
            oneWeekAgo.setHours(0, 0, 0, 0);
            this.dateFilter = [oneWeekAgo, moment()];
        },
        mounted: function () {

        },
        computed: {
            partnersList() {
                return this.$store.getters.ALL_PARTNERS;
            },
            knowledgeBaseArticlesStatuses() {
                return this.$store.getters.KNOWLEDGE_BASE_STATUSES;
            },
            orderTypesList() {
                return this.$store.getters.ALL_ORDER_TYPES;
            },
        },
        methods: {
            getQuery() {
                return this.listQuery;
            },
            filterStatusWrap: function(scroll = false) {
                this.condition.isShowFilter = ! this.condition.isShowFilter;

                if (scroll) {
                    let filter = this.$el.querySelector(".insurance-history__menu__filters");
                    filter.scrollTop = filter.scrollHeight;
                }
            },
            findTags(query) {
                if (query.length < 3) {
                    this.foundTags = [];
                    return false;
                }
                console.log('asyncFind', query);
                this.tagListLoading = true;
                const params = {
                    tag_name: query,
                };

                findTags(params)
                    .then(response => {
                        if (response && response.length > 0) {
                            this.foundTags = [];
                            response.map(entry => {
                                this.foundTags.push({ tag_name: entry.tag_name, id: entry.id });
                            });
                        }
                    })
                    .catch(error => {
                        this.showError(error);
                    })
                    .finally(() => (this.tagListLoading = false));

                console.log(this.clientList);
            },

            tapQuery(query) {
                this.listQuery.searchText = query.searchName;
            },
            isActiveQuery() {

            },
            getList() {
                this.condition.isShowFilter = false;
                return this.$emit('init-articles-list');
            },
            initArticlesList: function() {
                return this.$emit('init-articles-list');
            }
        }
    }
</script>

<style scoped>
    .fade-enter-active, .fade-leave-active {
        transition: opacity .5s;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
        opacity: 0;
    }
    .clients button{
      display: initial;
    }
</style>
