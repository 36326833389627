<script>
// import displaySettings from "@/mixin/displaySettings";
// import displaySettings from "@/utils/display-settings"
import DisplaySetting from "@/utils/display-settings";
import Vue from 'vue'

Vue.use(DisplaySetting);

export default {
    name: "UpdateApp",
    // mixins: [displaySettings],
    data() {
        return {
            dialogVisible: true
        }
    },
    methods: {
        show() {
            this.dialogVisible = true
        },
        updatePage() {
            location.reload(true)
        }
    }
}
</script>

<template>
    <div>
        <!--    :before-close="handleClose"-->
        <el-dialog
            center
            title="Нова версія додатку"
            :visible.sync="dialogVisible"
            :width="$isMobile ? '100%': '30%'"
        >
            <span>Для коректної роботи оновіть сторінку</span>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">Відминити</el-button>
                <el-button type="primary" @click="updatePage">Оновити сторінку</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<style scoped>
* >>> .el-dialog__body {
    text-align: center;
}
</style>
