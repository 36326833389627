export default {
     data() {
        return {
            carTypes2:{
                "B1": {"name":"Легкові автомобілі","group":"CAR", "external_id":"B1", "engine":"до 1600 см3", "engines":[{"name":"до 1600 см3","value":"B1"},  {"name":"1601-2000 см3","value":"B2"},{"name":"2001-3000 см3","value":"B3"},{"name":"более 3001 см3","value":"B4"}]
                },
                "B2": {"name":"Легкові автомобілі","group":"CAR", "external_id":"B2", "engine":"1601-2000 см3", "engines":[{"name":"до 1600 см3","value":"B1"},  {"name":"1601-2000 см3","value":"B2"},{"name":"2001-3000 см3","value":"B3"},{"name":"более 3001 см3","value":"B4"}]
                },
                "B3": {"name":"Легкові автомобілі","group":"CAR", "external_id":"B3", "engine":"2001-3000 см3", "engines":[{"name":"до 1600 см3","value":"B1"},  {"name":"1601-2000 см3","value":"B2"},{"name":"2001-3000 см3","value":"B3"},{"name":"более 3001 см3","value":"B4"}]
                },
                "B4": {"name":"Легкові автомобілі","group":"CAR", "external_id":"B4", "engine":"более 3001 см3", "engines":[{"name":"до 1600 см3","value":"B1"},  {"name":"1601-2000 см3","value":"B2"},{"name":"2001-3000 см3","value":"B3"},{"name":"более 3001 см3","value":"B4"}]
                },
                "D1": {"name":"Автобус","group":"BUS", "external_id":"D1", "engine":"менше 20 осіб","engines":[{"name":"менше 20 осіб","value":"D1"},
                        {"name":"більше 20 осіб","value":"D2"}]},
                "D2": {"name":"Автобус","group":"BUS", "external_id":"D2", "engine":"більше 20 осіб","engines":[{"name":"менше 20 осіб","value":"D1"},
                        {"name":"більше 20 осіб","value":"D2"}]},
                "C1": {"name":"Вантажні автомобілі","group":"CARGO", "external_id":"C1", "engine":"менше 2-х тонн", "engines":[{"name":"менше 2-х тонн","value":"C1"},
                        {"name":"більше 2-х тонн","value":"C2"}]},
                "C2": {"name":"Вантажні автомобілі","group":"CARGO", "external_id":"C2", "engine":"більше 2-х тонн", "engines":[{"name":"менше 2-х тонн","value":"C1"},{"name":"більше 2-х тонн","value":"C2"}]},
                "E": {"name":"Причіп, Трейлер","group":"TRAILER", "external_id":"E", "engine":"До вантажного авто",
                    "engines":[{"name":"До вантажного авто","value":"E"},
                        {"name":"До легкового авто","value":"F"}]},
                "F": {"name":"Причіп, Трейлер","group":"TRAILER", "external_id":"F", "engine":"До легкового авто",
                    "engines":[{"name":"До вантажного авто","value":"E"},
                        {"name":"До легкового авто","value":"F"}]},
                "A1": {"name":"Мотоцикли та моторолери","group":"BIKE", "external_id":"A1",
                    "engine": "300 см3 або менше", "engines":[{"name":"300 см3 або менше","value":"A1"},
                        {"name":"більше 300 см3","value":"A2"}]},
                "A2": {"name":"Мотоцикли та моторолери","group":"BIKE", "external_id":"A2",
                    "engine":"більше 300 см3", "engines":[{"name":"300 см3 або менше","value":"A1"},
                        {"name":"більше 300 см3","value":"A2"}]},
                "B5": {"name":"Легковий електромобіль","group":"CAR","external_id":"B5","engine":"—","engines":[]}
            },
            periodOptions: [
                {"id": 15, "name" : "15 д" },
                {"id": 1, "name" : "1 міс" },
                {"id": 2, "name" : "2 міс" },
                {"id": 3, "name" : "3 міс" },
                {"id": 4, "name" : "4 міс" },
                {"id": 5, "name" : "5 міс" },
                {"id": 6, "name" : "6 міс" },
                {"id": 7, "name" : "7 міс" },
                {"id": 8, "name" : "8 міс" },
                {"id": 9, "name" : "9 міс" },
                {"id": 10, "name" : "10 міс" },
                {"id": 11, "name" : "11 міс" },
                {"id": 12, "name" : "12 міс" },
            ],
        };
    },
    methods: {
        /**
         * @param {Array} calc_car_plate - Массив с номерами автомобилей.
         * @param {Array} step_car - Массив с номерами автомобилей из Diia или массив из dataCompinatePlate.
         * @param {Boolean} diiaFlag - Массив с номерами автомобилей из Diia или массив из dataCompinatePlate.
         */
        mixinCheckVehicle: function (params, diiaFlag = false) {
            let foundPlate, curPlate, calcCar,calc_car_plate,step_car,calc_car_type;
            //Выбираем ти продукта
            if(params.tariffType == 'osago'){
               return this.osagoModule(params, diiaFlag);
            } else{
                this.hideAnimateModal()
                //this.$store.commit('diia/CLEAR_VEHICLE_LICENSE');
                return false;
            }

            return false;
        },
        hideAnimateModal() {
            this.$root.$emit('hideAnimateModalPreloader');
            $(".modal-backdrop").css('display','none')
        },

        async osagoModule(params, diiaFlag){
            let foundPlate, foundPlateArr, curPlate, calcCar, calc_car_plateinfo = {};
            let calc_car_plate = (params.calc_car_plate[0] === null) ? null : params.calc_car_plate;
            let calc_car_type = params.calc_car_type;
            let stepCarType = params.curCarType;
            let stepCarInfo = params.curCarInfo;
            let step_car = params.step_car;
            let calc_car_info = params.calcCarInfo;
            let step_city_data = params.stepCityData;
            if ((typeof step_car === 'object' || typeof step_car === 'string') && step_car &&
                ((Array.isArray(calc_car_plate) && calc_car_plate.length > 0) || calc_car_plate == null) &&
                !this.leavePlateFlag) {
                if(calc_car_plate !== null && !!calc_car_plate[0]) {
                    calc_car_plate = this.mixinNormalizeLicensePlate(calc_car_plate[0]);
                    calc_car_plateinfo = await this.mixinGetCarInfo(calc_car_plate); //Делаем поиск информации об авто которые было при калькуляции
                }
                curPlate = await this.mixinGetCarInfo(step_car); //Делаем поиск информации об авто которое было введено после калькуляции (Данный кейс работает на шаге заполнения данных об авто)

                //if(!curPlate.cap_class){
                    curPlate.cap_class = stepCarType;
                //}
                if(!curPlate.vin){
                    curPlate.vin = stepCarInfo.vin;
                }
                if(!curPlate.year){
                    curPlate.makeYear = stepCarInfo.year;
                }
                if(!curPlate.mark){
                    curPlate.mark = stepCarInfo.brand;
                }
                if(!curPlate.model){
                    curPlate.model = stepCarInfo.model;
                }

                curPlate.carType = this.carTypes2[curPlate.cap_class];
                curPlate.kindBody = curPlate?.carType?.name || "";
                curPlate.capacity = curPlate?.carType?.engine || "";

                if(step_city_data){
                    curPlate.zone = step_city_data.zone;
                    curPlate.address = step_city_data.name_full_name_ua;
                    curPlate.region = step_city_data.name;
                }

                calc_car_plateinfo.address = (params.calcDataCity?.name_full_name_ua)?params.calcDataCity.name_full_name_ua:calc_car_plateinfo?.address || null;
                calc_car_plateinfo.zone = (params.calcDataCity?.zone !== undefined && params.calcDataCity?.zone !== null) ? params.calcDataCity.zone : (calc_car_plateinfo?.zone || null);

                calc_car_plateinfo.cap_class = calc_car_type ;
                calc_car_plateinfo.kindBody = this.carTypes2[calc_car_plateinfo.cap_class].name;
                calc_car_plateinfo.capacity = this.carTypes2[calc_car_plateinfo.cap_class].engine || this.carTypes2[calc_car_plateinfo.cap_class].types[0]['name'];
                if(calc_car_plate !== null){
                    if(!calc_car_plateinfo.zone || !curPlate.zone){
                        if(calc_car_plateinfo.address && !curPlate.address){
                            curPlate.address = calc_car_plateinfo.address;// если по номеру не нашли город регистрации, берем его из калькуляции
                        }
                        foundPlate = (calc_car_plateinfo.cap_class === curPlate.cap_class); //Делаем поиск типа и Зоны авто который ввели на шаге Car с типом который был при калькуляции
                    }else if(calc_car_info && calc_car_info.birthdayAt && stepCarInfo && stepCarInfo.birthdayAt){
                        foundPlate = calc_car_info.birthdayAt == stepCarInfo.birthdayAt;
                    }
                    else{
                        foundPlate = (calc_car_plateinfo.cap_class === curPlate.cap_class && calc_car_plateinfo.zone == curPlate.zone); //Делаем поиск типа и Зоны авто который ввели на шаге Car с типом и Зоной который был при калькуляции
                    }
                }else{
                    foundPlate = (calc_car_type == curPlate.cap_class && calc_car_plateinfo.zone == curPlate.zone);
                }
/*console.log('params',params);
console.log('calc_car_plate',calc_car_plate);
console.log('calc_car_type',calc_car_type);
console.log('calc_car_plateinfo',calc_car_plateinfo);
console.log('stepCarInfo',stepCarInfo);
console.log('calc_car_info',calc_car_info);
console.log('curPlate',curPlate);
console.log('foundPlate',foundPlate);*/
                if (curPlate && !foundPlate) { //Если данные авто не совпадают показываем popup
                    this.hideAnimateModal();

                    $("#modalCompinatePlate").modal({
                        backdrop: "static",
                        keyboard: true,
                        show: true,
                    });

                    //передаем в popup необходимые данные
                    this.$root.$emit('setCompinatePlateParameters', {
                        calcCar: calc_car_plateinfo,
                        diiaCar: curPlate,
                        curPlateInfo: curPlate,
                        order: this.orderData,
                        privilegeType: this.privilegeType,
                        diiaFlag: diiaFlag,
                        params: params,
                        calc_car_info: calc_car_info,
                        stepCarInfo: stepCarInfo,
                    });


                }else{ //если типі авто совпадают просто прячем прелоадер и отправляем собітие об окончании проверки
                    this.hideAnimateModal();
                    this.$root.$emit('completeCalcAfterChangePlate', {
                        plate: curPlate.licensePlate,
                        item:false,
                        status:'success',
                        diiaData: curPlate,
                        diiaFlag: diiaFlag,
                        changedCity:params.changedCity
                    });
                    //this.$store.commit('diia/CLEAR_VEHICLE_LICENSE');
                }
                return foundPlate;
            }else{
                this.hideAnimateModal();
                //this.$store.commit('diia/CLEAR_VEHICLE_LICENSE');
            }

            return false;
        },
        async mixinFindPlateArr(calc_car_plateinfo,step_car) {
            let stepCarinfo = null;
            const promises = step_car.map(async (item, index) => {
                const normalizedItemPlate = this.mixinNormalizeLicensePlate(item.ua.licensePlate);
                const carinfo = await this.mixinGetCarInfo(normalizedItemPlate);
                carinfo.diiaData = item.ua
                if(index == 0){
                    stepCarinfo = carinfo;
                }
                return carinfo;
            });
            const carInfos = await Promise.all(promises);
            const foundCapClass = carInfos.find((carinfo) => (calc_car_plateinfo.cap_class === carinfo.cap_class && calc_car_plateinfo.zone == carinfo.zone));
            return {'foundCapClass':foundCapClass, 'stepCarinfo':stepCarinfo};
        },
        async mixinFindCarTypeArr(calc_car_plate,step_car, params) {
           let stepCarinfo = null;
           const promises = step_car.map( async (item, index) => {
               const normalizedItemPlate = this.mixinNormalizeLicensePlate(item.ua.licensePlate);
               const carinfo = await this.mixinGetCarInfo(normalizedItemPlate);
               carinfo.diiaData = item.ua
               if (index == 0) {
                   stepCarinfo = carinfo;
               }
               return carinfo;
           });
           const carInfos = await Promise.all(promises);
           const foundCarInfo = carInfos.find((carinfo) => this.carTypes2[calc_car_plate].group === this.carTypes2[carinfo.cap_class].group);
           if(foundCarInfo){
               stepCarinfo.diiaData.parsedData.cdbMtibuCode = stepCarinfo.cdbMtibuCode = foundCarInfo?.cdbMtibuCode || params.calcDataCity?.code || null;
               if(!stepCarinfo.address){
                   stepCarinfo.address = params?.calcDataCity?.full_name;
               }
               if(!stepCarinfo.vin){
                   stepCarinfo.vin = stepCarinfo.diiaData.parsedData.vin;
               }
           }
           return {'foundCarInfo':foundCarInfo, 'stepCarinfo':stepCarinfo};
        },
        mixinFindPlate(calc_car_plate,step_car) {
            return calc_car_plate.includes(step_car) ? step_car : false;
        },
        mixinNormalizeLicensePlate(plate) {
            // Удалите все пробелы и преобразуйте в верхний регистр
            return plate.replace(/\s/g, '').toUpperCase();
        },
        mixinNormalizeLicensePlateArr(plateArr) {
            // Удалите все пробелы и преобразуйте в верхний регистр
            let res = [];
            plateArr.forEach((plate) => {
                res.push(this.mixinNormalizeLicensePlate(plate));
            });
            return res;
        },
        async mixinGetCarInfo(plate) {
            let result = {};

            result = await axios({url: '/api/directories/car/' + plate.trim(), method: 'GET'}).then(({data}) => {
                let result = data.data;
                let foundBrand = null;
                let foundModel = null;
                if(data.data == null) {
                    return  {};
                }
                let partsAdress = (data.data.city) ? data.data.city.name_full_name_ua : null;
                return {
                    licensePlate : plate,
                    vin : data.data.vin,
                    makeYear : data.data.year,
                    cap_class: data.data.autoCategory,
                    kindBody : this.carTypes2[data.data.autoCategory]?.name || "",
                    capacity : this.carTypes2[data.data.autoCategory]?.engine || "",
                    group : this.carTypes2[data.data.autoCategory]?.group || "",
                    mark : data.data?.mark?.name || data.data?.advanced_info?.brand || null,
                    model : data.data?.model?.name || data.data?.advanced_info?.model || null,
                    address : (partsAdress) ? partsAdress : null,
                    region : data.data?.city?.name_ua,
                    zone :  data.data?.city?.zone || 0,
                    cdbMtibuCode : data.data?.city?.kaotuu || 0,
                    brand_model : data.data.model};
            }).catch(err => (console.log(err)));


            return result;
        },
    }
};
