const state = {
    types: [
           {name: '15 дней', id: 15},
           {name: '21 дней', id: 21},
           {name: '1 месяц', id: 1},
           {name: '2 месяца', id: 2},
           {name: '3 месяца', id: 3},
           {name: '4 месяца', id: 4},
           {name: '5 месяцев', id: 5},
           {name: '6 месяцев', id: 6},
           {name: '7 месяцев', id: 7},
           {name: '8 месяцев', id: 8},
           {name: '9 месяцев', id: 9},
           {name: '10 месяцев', id: 10},
           {name: '11 месяцев', id: 11},
           {name: '12 месяцев', id: 12},
         ],
};

const getters = {
    PERIOD_TYPES: state => state.types
};

export default {
    state,
    getters
};
