import UpdateApp from "@/components/app/modals/UpdateApp.vue";

export default {
    install(Vue, options = {}) {
        const { interval = 30000, versionFile = '/version.json' } = options;

        Vue.prototype.$checkForUpdates = async function () {
            try {
                const response = await fetch(`${versionFile}?cache=${Date.now()}`, { cache: 'no-store' });
                const { version } = await response.json();

                if (this.$root.currentVersion && this.$root.currentVersion !== version) {
                    this.$showUpdateModal();
                } else if (!this.$root.currentVersion) {
                    this.$root.currentVersion = version;
                }
            } catch (error) {
                console.error('Failed to fetch version.json:', error);
            }
        };

        Vue.prototype.$showUpdateModal = function () {
            if (!this.$root.$updateModal) {
                console.log('testmodal')
                const ModalConstructor = Vue.extend(UpdateApp);
                this.$root.$updateModal = new ModalConstructor().$mount();
                document.body.appendChild(this.$root.$updateModal.$el);
            }
            this.$root.$updateModal.show();
        };

        Vue.mixin({
            data() {
                return {
                    currentVersion: null,
                };
            },
            mounted() {
                if (this.$root === this && !this.$root._pluginInitialized) {
                    this.$root._pluginInitialized = true;
                    this.$checkForUpdates()
                    setInterval(() => this.$checkForUpdates(), interval);
                }
            },
        });
    }
}
