export default {
    data: function () {
        return {

        };
    },
    created: async function () {

    },
    computed: {
        orderStatusesMixin: function () {
            return this.$store.getters.ORDER_STATUSES;
        },
        concludeStatus: function () {
            return this.$_.findWhere(this.orderStatusesMixin, { type: 'concluded'})?.id;
        },
        draftStatus: function () {
            return this.$_.findWhere(this.orderStatusesMixin, { type: 'draft' })?.id;
        },
        declaredStatus: function () {
            return this.$_.findWhere(this.orderStatusesMixin, { type: 'declared'})?.id;
        },
        overdueStatus: function () {
            return this.$_.findWhere(this.orderStatusesMixin, { type: 'overdue'})?.id;
        },
        canceledStatus: function () {
            return this.$_.findWhere(this.orderStatusesMixin, { type: 'canceled'})?.id;
        },
        prolongationStatus: function () {
            //TODO ид просто взят для того,что бы хоть какой то был.
            // То есть это костыль. Скорее всего врменный, так как связан с тем,
            // что бы во фронт вставить без проблемно новый статус.
            return  { name: 'Пролонгація', id: 15, type: "prolongation"};
        },
    },
    methods:{
        showStatusId: function(order) {
            return this.$_.findWhere(this.orderStatusesMixin, {id: parseInt(order.statusId)})?.id;
        },
        showStatusName: function(order) {
            return this.$_.findWhere(this.orderStatusesMixin, {id: parseInt(order.statusId)})?.name;
        },
        getStatusClass: function(order) {
            return 'status-' + this.$_.findWhere(this.orderStatusesMixin, {id: parseInt(order.statusId)})?.type;
        },
    }
}
