<template>
    <main class="order order-flight">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="navsteps navstepts-flight">
                        <div class="navsteps__item">
                            <div class="number">1</div>
                            <div class="descr">Розрахунок</div>
                        </div>
                        <div class="navsteps__item">
                            <div class="number">2</div>
                            <div class="descr">Вибір СК</div>
                        </div>
                        <div class="navsteps__item active">
                            <div class="number">3</div>
                            <div class="descr">Оформлення</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="orders osago">
            <div class="container">
                <div class="row row-reverce">
                    <div class="col-lg-12">
                        <!--                        <router-link :to="{ path: $routerHistory.previous().path }" class="go-back">-->
                        <a href="javascript:void(0);" class="go-back" @click="$router.go(-1)">
                            Повернутися до оформлення
                        </a>
                        <!--                        </router-link>-->

                        <div class="params-search">
                            <a class="params-search__toggler collapsed" data-toggle="collapse" data-target="#searchcollapse" aria-expanded="false" aria-controls="#searchcollapse">
                                <span>Параметри полісу</span>
                                <svg width="11" height="6" viewBox="0 0 11 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10.2795 2.75059e-08L11 0.69496L5.5 6L-2.31891e-07 0.694961L0.7205 4.45343e-07L5.50275 4.61273L10.2795 2.75059e-08Z" fill="black"/>
                                </svg>
                            </a>
                            <div class="collapse" id="searchcollapse">
                                <div class="params-search__content">
                                    <div class="info">
                                        <b>{{ offerInfo.publicName }}</b>
                                        <div class="logo">
                                            <img v-if="offerInfo.company.logo" :src="offerInfo.company.logo" alt="logo">
                                            <img v-else src="images/aboutUs-insurance_icon.svg" alt="logo">
                                        </div>
                                    </div>
                                    <div class="params">
                                        <div class="params-item">
                                            <p>Компанія:</p>
                                            <span>{{ offerInfo.companyNamePublic }}</span>
                                        </div>
                                        <div class="params-item">
                                            <p>Транспортний засіб:</p>
                                            <span>{{ searchInfo.carType.name}}</span>
                                        </div>
                                        <div class="params-item">
                                            <p>Покриття:</p>
                                            <span>{{ searchInfo.country.name}}</span>
                                        </div>
                                        <div class="params-item">
                                            <p>Тип користувача:</p>
                                            <span>{{ searchInfo.userType.value}}</span>
                                        </div>
                                        <div class="params-item">
                                            <p>Початок дії:</p>
                                            <span>{{ searchInfo.startDate}}</span>
                                        </div>
                                        <div class="params-item">
                                            <p>Період:</p>
                                            <span>{{searchInfo.periodOption.name}}</span>
                                        </div>
                                        <div class="params-item">
                                            <p>Вартість:</p>
                                            <span>{{offerInfo.price}} грн</span>
                                        </div>

                                    </div>
                                    <div class="params-price">
                                        <p>Загальна вартість:</p>
                                        <b v-if="order.fullPrice">{{order.fullPrice}} <span>грн</span></b>
                                        <b v-else>{{order.price}} <span>грн</span></b>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row flex-lg-row-reverse" style="position: relative;">
                            <div class="finnaly-column col-lg-3">
                                <div class="sticky-wrapp">
                                    <AutocivilPromo></AutocivilPromo>
                                </div>
                            </div>

                            <form class="finnaly-form col-lg-9" >
                                <div class="row">
                                    <div class="col-12">
                                        <h2>Оформления страхування</h2>
                                    </div>
                                    <div class="col-12">
                                        <h3>
                                            Перевірте дані та перейдіть до оплати:
                                        </h3>
                                    </div>
                                    <div ref="ticketsection" :class="['row col-12 finnaly-container', {'disabled-container' : !createTicketInfo}]">
                                        <button v-if="enableEditform" class="edit-btn" type="button" @click="createTicketInfo = !createTicketInfo">
                                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M21.5796 4.546L17.6662 0.612662C17.4076 0.355372 17.0577 0.210937 16.6929 0.210938C16.3281 0.210938 15.9781 0.355372 15.7196 0.612662L1.84622 14.466L0.57955 19.9327C0.535854 20.1325 0.537352 20.3396 0.583934 20.5388C0.630516 20.7379 0.721005 20.9242 0.848792 21.0839C0.976578 21.2437 1.13843 21.3728 1.32253 21.462C1.50663 21.5512 1.70833 21.5981 1.91288 21.5993C2.0082 21.609 2.10424 21.609 2.19955 21.5993L7.72622 20.3327L21.5796 6.49266C21.8368 6.23407 21.9813 5.88412 21.9813 5.51933C21.9813 5.15454 21.8368 4.80459 21.5796 4.546ZM7.05955 19.1327L1.87955 20.2193L3.05955 15.1393L13.4396 4.79933L17.4396 8.79933L7.05955 19.1327ZM18.3329 7.83266L14.3329 3.83266L16.6529 1.526L20.5862 5.526L18.3329 7.83266Z" fill="#007BBB"/>
                                            </svg>
                                        </button>
                                        <button v-if="enableEditform" class="save-btn" type="button" @click="saveChange()">
                                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M21.5796 4.546L17.6662 0.612662C17.4076 0.355372 17.0577 0.210937 16.6929 0.210938C16.3281 0.210938 15.9781 0.355372 15.7196 0.612662L1.84622 14.466L0.57955 19.9327C0.535854 20.1325 0.537352 20.3396 0.583934 20.5388C0.630516 20.7379 0.721005 20.9242 0.848792 21.0839C0.976578 21.2437 1.13843 21.3728 1.32253 21.462C1.50663 21.5512 1.70833 21.5981 1.91288 21.5993C2.0082 21.609 2.10424 21.609 2.19955 21.5993L7.72622 20.3327L21.5796 6.49266C21.8368 6.23407 21.9813 5.88412 21.9813 5.51933C21.9813 5.15454 21.8368 4.80459 21.5796 4.546ZM7.05955 19.1327L1.87955 20.2193L3.05955 15.1393L13.4396 4.79933L17.4396 8.79933L7.05955 19.1327ZM18.3329 7.83266L14.3329 3.83266L16.6529 1.526L20.5862 5.526L18.3329 7.83266Z" fill="#fff"/>
                                            </svg>
                                            <span>Зберегти зміни</span>
                                        </button>
                                        <div class="col-12">
                                            <div class="info-container_heading">Дані зеленой карти</div>
                                        </div>

                                        <div class="col-6">
                                            <div class="info-block">
                                                <b>Номерний знак</b>
                                                <p>{{ car.number }}</p>
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="info-block">
                                                <b>Марка</b>
                                                <p>{{ car.brand }}</p>
                                            </div>
                                        </div>

                                        <div class="col-6">
                                            <div class="info-block">
                                                <b>Модель</b>
                                                <p>{{ car.model }}</p>
                                            </div>
                                        </div>

                                        <div class="col-6">
                                            <div class="info-block">
                                                <b>Рік випуску авто</b>
                                                <p>{{ car.year }}</p>
                                            </div>
                                        </div>

                                        <div class="col-6">
                                            <div class="info-block">
                                                <b>VIN номер авто</b>
                                                <p>{{ car.vin }}</p>
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="info-block">
                                                <b>Маса без навантаження, кг</b>
                                                <p>{{ car.ownWeight }}</p>
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="info-block">
                                                <b>Повна маса, кг</b>
                                                <p>{{ car.totalWeight }}</p>
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="info-block">
                                                <b>Кількість місць для сидіння, включно з місцем водія</b>
                                                <p>{{ car.nSeating }}</p>
                                            </div>
                                        </div>
                                        <div class="col-6" v-if="car.autoCategory !== 'B5'">
                                            <div class="info-block">
                                                <b>Об’єм двигуна</b>
                                                <p>{{ car.engineSize }}</p>
                                            </div>
                                        </div>
                                        <div class="col-6" v-else>
                                            <div class="info-block">
                                                <b>Потужність (для електромобілів), кВт</b>
                                                <p>{{ car.kvt }}</p>
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="info-block">
                                                <b>Місто реєстрації</b>
                                                <p>{{ car.city.name_full_name_ua ? car.city.name_full_name_ua : car.city.name }}</p>
                                            </div>
                                        </div>
                                       <div class="col-6">
                                           <div class="info-block">
                                               <b>Початок дії</b>
                                               <button type="button" v-if="! confirmContractStatus" @click="changestartOrderDate()" v-b-tooltip="'Змінити дату початку'" class="history-order__form-btn edit-limit">
                                                   <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                       <path d="M15.045 5.1023C15.423 4.71657 15.631 4.2043 15.631 3.65937C15.631 3.11444 15.423 2.60217 15.045 2.21644L13.459 0.59799C13.081 0.212256 12.579 0 12.045 0C11.511 0 11.009 0.212256 10.632 0.596969L0 11.4128V15.9182H4.413L15.045 5.1023ZM12.045 2.04092L13.632 3.65835L12.042 5.27476L10.456 3.65733L12.045 2.04092ZM2 13.8772V12.2598L9.04 5.09822L10.626 6.71667L3.587 13.8772H2ZM0 17.9591H16V20H0V17.9591Z" fill="#007BBB"/>
                                                   </svg>
                                               </button>

                                               <date-picker v-model="startOrderDate" :config="optionsStartOrderDate" :class="['form-control','date-input']" placeholder="Введіть дату" :disabled="startOrderDateDisabled" ></date-picker>
                                           </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <hr>
                                    </div>
                                    <div :class="['row col-12 finnaly-container', {'disabled-container' : !createUserInfo}]">
                                        <button v-if="enableEditform" class="edit-btn" type="button" @click="createUserInfo = !createUserInfo">
                                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M21.5796 4.546L17.6662 0.612662C17.4076 0.355372 17.0577 0.210937 16.6929 0.210938C16.3281 0.210938 15.9781 0.355372 15.7196 0.612662L1.84622 14.466L0.57955 19.9327C0.535854 20.1325 0.537352 20.3396 0.583934 20.5388C0.630516 20.7379 0.721005 20.9242 0.848792 21.0839C0.976578 21.2437 1.13843 21.3728 1.32253 21.462C1.50663 21.5512 1.70833 21.5981 1.91288 21.5993C2.0082 21.609 2.10424 21.609 2.19955 21.5993L7.72622 20.3327L21.5796 6.49266C21.8368 6.23407 21.9813 5.88412 21.9813 5.51933C21.9813 5.15454 21.8368 4.80459 21.5796 4.546ZM7.05955 19.1327L1.87955 20.2193L3.05955 15.1393L13.4396 4.79933L17.4396 8.79933L7.05955 19.1327ZM18.3329 7.83266L14.3329 3.83266L16.6529 1.526L20.5862 5.526L18.3329 7.83266Z" fill="#007BBB"/>
                                            </svg>
                                        </button>
                                        <button v-if="enableEditform" class="save-btn" type="button" @click="saveChange()">
                                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M21.5796 4.546L17.6662 0.612662C17.4076 0.355372 17.0577 0.210937 16.6929 0.210938C16.3281 0.210938 15.9781 0.355372 15.7196 0.612662L1.84622 14.466L0.57955 19.9327C0.535854 20.1325 0.537352 20.3396 0.583934 20.5388C0.630516 20.7379 0.721005 20.9242 0.848792 21.0839C0.976578 21.2437 1.13843 21.3728 1.32253 21.462C1.50663 21.5512 1.70833 21.5981 1.91288 21.5993C2.0082 21.609 2.10424 21.609 2.19955 21.5993L7.72622 20.3327L21.5796 6.49266C21.8368 6.23407 21.9813 5.88412 21.9813 5.51933C21.9813 5.15454 21.8368 4.80459 21.5796 4.546ZM7.05955 19.1327L1.87955 20.2193L3.05955 15.1393L13.4396 4.79933L17.4396 8.79933L7.05955 19.1327ZM18.3329 7.83266L14.3329 3.83266L16.6529 1.526L20.5862 5.526L18.3329 7.83266Z" fill="#fff"/>
                                            </svg>
                                            <span>Зберегти зміни</span>
                                        </button>
                                        <div class="col-12">
                                            <div class="info-container_heading">Дані страхувальника</div>
                                        </div>

                                        <div class="col-6" v-if="searchInfo.userType.id == 2">
                                            <div class="info-block">
                                                <b>ЄДРПОУ компанії</b>
                                                <p>{{ order.info.erdpyCode }}</p>
                                            </div>
                                        </div>
                                        <div class="col-6" v-if="searchInfo.userType.id == 2">
                                            <div class="info-block">
                                                <b>Назва компанії</b>
                                                <p>{{ order.info.name }}</p>
                                            </div>
                                        </div>
                                        <div v-else-if="searchInfo.userType.id == 1" class="col-12 row passport-block">
                                            <div class="col-6">
                                                <div class="info-block">
                                                    <b>Ім’я</b>
                                                    <p>{{ order.info.name }}</p>
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="info-block">
                                                    <b>Прізвище</b>
                                                    <p>{{ order.info.surname }}</p>
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="info-block">
                                                    <b>Дата народження</b>
                                                    <p>{{ order.info.dateBirth }}</p>
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="info-block">
                                                    <b>ІПН</b>
                                                    <p>{{ order.info.identificationCode }}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="info-block">
                                                <b>Номер телефону</b>
                                                <p>{{ order.info.phone }}</p>
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="info-block">
                                                <b>Email</b>
                                                <p>{{ order.info.mail }}</p>
                                            </div>
                                        </div>
                                        <div class="passport-row row" v-if ="searchInfo.userType.id != 2 && order.info.withoutDocuments == 0">
                                            <div v-if="order.info.documentation.type != 8" class="col-lg-6">
                                                <div class="info-block">
                                                    <b>Серія документа</b>
                                                    <p>{{ order.info.documentation.serial }}</p>
                                                </div>
                                            </div>
                                            <div class="col-lg-6">
                                                <div class="info-block">
                                                    <b>Номер паспорта</b>
                                                    <p>{{ order.info.documentation.number }}</p>
                                                </div>
                                            </div>
                                            <div v-if="order.info.documentation.type != 8" class="col-lg-6">
                                                <div class="info-block">
                                                    <b>Ким виданий</b>
                                                    <p>{{ order.info.documentation.issuedBy }}</p>
                                                </div>
                                            </div>
                                            <div v-if="order.info.documentation.type != 8" class="col-lg-6">
                                                <div class="info-block">
                                                    <b>Дата видачі</b>
                                                    <p>{{ order.info.documentation.dateOfIssue }}</p>
                                                </div>
                                            </div>
                                            <div v-if="order.info.documentation.endDateOfIssue" class="col-lg-6">
                                                <div class="info-block">
                                                    <b>Закінчення дії</b>
                                                    <p>{{ order.info.documentation.endDateOfIssue }}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <hr/>
                                    </div>
                                    <div class="col-lg-3 price-block">
                                        <div class="price-label">Загальна вартість</div>
                                    </div>
                                    <div class="col-lg-3 price-block">
                                        <div class="price-value" v-if="order.fullPrice">{{order.fullPrice}}</div>
                                        <div class="price-value" v-else>{{order.price}} грн</div>
                                    </div>
                                    <use-bonus
                                        class="col-lg-6"
                                        @show-earnings="showEarnings = $event"
                                        @use-bonuses="useBonuses = $event"
                                    ></use-bonus>
                                </div>
                                <earnings v-show="earnings && showEarnings"
                                          :earnings="earnings"
                                          :use-bonuses="useBonuses"
                                          :data-is-load-earning="dataIsLoadEarning"
                                          v-model="bonusSystemValue"
                                ></earnings>
                                <div class="row">

                                    <div class="col-lg-12">
                                        <hr/>
                                    </div>

                                    <div v-if="confirmContractStatus" class="col-lg-6 text-center position-relative">
                                        <button
                                            type="button"
                                            v-on:click="showDraft()"
                                            class="btn btn-dark">
                                            Переглянути пропозицію
                                        </button>
                                    </div>
                                    <div v-if="! confirmContractStatus" class="col-lg-6 text-center position-relative">
                                        <router-link to="/greencard-steps">
                                            <button
                                                type="button"
                                                class="btn btn-warning">
                                                Редагувати дані
                                            </button>
                                        </router-link>
                                    </div>
                                    <div v-if="confirmContractStatus" class="col-lg-6 text-center position-relative">
                                        <button
                                            type="button"
                                            @click="showAllOrderPaymentWays()"
                                            class="btn btn-warning">
                                            Варіанти оплати
                                        </button>
                                    </div>

                                    <div class="col-lg-12">
                                        <hr/>
                                    </div>

                                    <div class="col-lg-12" v-if="! confirmContractStatus">
                                        <div class="form-check">
                                            <input v-model="personalCheck" type="checkbox" id="personal" class="form-check-input">
                                            <label for="personal" :class="['form-check-label', { error : !personalCheck && submitCheck}]">Згоден з обробкою персональних даних. Дані будуть передані в страхову компанію для формування поліса</label>
                                        </div>
                                    </div>
                                  <otp-block
                                      :data-otp-flags="otpFlags"
                                      :data-confirm-contract-status="confirmContractStatus"
                                      @formSendOtp="formSendOtp"
                                      @otpCheck="otpCheck"
                                      @otpChenged="otpChenged"
                                  ></otp-block>
                                    <div v-if="! confirmContractStatus" class="col-12 text-center position-relative">
                                        <button
                                            type="button"
                                            v-on:click="makeContract('make')"
                                            class="btn btn-blue">Заявити</button>
                                    </div>
                                    <div v-else-if="otpFlags.otpStatus && (confirmContractStatus && !otpFlags.confirmOtpStatus)" class="col-12 text-center position-relative">
                                      <button
                                          type="button"
                                          :disabled="!otpFlags.confirmOtpStatus"
                                          class="btn btn-dark-grey">Оформити</button>
                                    </div>
                                    <div v-else-if="confirmContractStatus && ((otpFlags.confirmOtpStatus && otpFlags.otpStatus) || !otpFlags.otpStatus)" class="col-12 text-center position-relative">
                                        <button
                                            type="button"
                                            v-on:click="confirmContract()"
                                            class="btn btn-blue">Оформити</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <modalDocument></modalDocument>
        <animationModal></animationModal>
        <modal-response-status :data-response="dataResponse" :data-product-type="dataProductType"></modal-response-status>
        <modal-response-confirm-status :data-response="dataResponse" :data-product-type="dataProductType"></modal-response-confirm-status>
        <modalSuccess @showload="downloadContract()"></modalSuccess>
        <choice-otp-sending-direction ref="ChoiceOtpSendingDirection" @sendOtpApi="sendOtpApi"></choice-otp-sending-direction>
        <modal-declared-success ref="modalDeclaredSuccess"></modal-declared-success>
        <modal-get-invoice-order ref="modalGetInvoiceOrder"></modal-get-invoice-order>
        <modal-get-instant-loan ref="modalGetInstantLoan"></modal-get-instant-loan>
        <modal-get-mono-pay-parts ref="showModalGetMonoPayParts"></modal-get-mono-pay-parts>
        <modal-get-all-payment-ways
            ref="modalGetAllPaymentWays"
            @showModalGetInvoiceOrder="showModalGetInvoiceOrder"
            @showModalGetInstantLoan="showModalGetInstantLoan"
            @showModalGetMonoPayParts="showModalGetMonoPayParts"
            @showModalGetInvoiceLegal="showModalGetInvoiceLegal"
            :is-legal-type="isLegalType"
        >
        </modal-get-all-payment-ways>

        <fin-monitoring :visible.sync="showFinMonitoringModal" @confirm="confirmFinMonitoring"></fin-monitoring>
    </main>
</template>


<script>
import animationModal from "@/components/app/modals/modal-payAnimation";
import Multiselect from 'vue-multiselect';
import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap';
import AutocivilPromo from '@/views/flight/components/AutocivilPromo.vue';
import modalDocument from '@/components/app/modals/modal-flight-document';
import modalSuccess from '@/components/app/modals/modal-green-card-success';
import citySearch from '@/components/app/forms/CitySearch.vue';
import countrySearch from "@/components/app/forms/CountrySearch";
import {email, maxLength, minLength, numeric, required, requiredIf} from 'vuelidate/lib/validators';
import {downloadInvoiceLegal, downloadOrder, getPaymentInvoice, showOrder} from "@/api/order";
import { contractGreenCard } from '@/api/contractGreenCard';
import { changeOrderGreenCard } from '@/api/changeOrderGreenCard';
import {orderGreenCard} from "@/api/orderGreenCard";
import {DeclaredSuccess as ModalDeclaredSuccess,
    GetAllPaymentWays as ModalGetAllPaymentWays,
    GetPaymentLink as ModalGetInvoiceOrder,
    GetInstantLoan as ModalGetInstantLoan,
    GetMonoPayParts as ModalGetMonoPayParts,
    ChoiceOtpSendingDirection,
} from "@/components/app/modals/order";
import otpBlock from "@/components/app/otp/otpBlock.vue";
import {otpFunc} from "@/mixin";
import Earnings from "@/components/app/bonusSystem/Earnings.vue";
import {list as greenCardCompaniesList} from "@/api/greenCardInsuranceCompany";
import UseBonus from "@/components/app/bonusSystem/UseBonus.vue";
import modalResponseStatus from '@/components/app/modals/modal-response-status.vue';
import modalResponseConfirmStatus from '@/components/app/modals/modal-response-confirm-status.vue';
import FinMonitoring from "@/components/app/modals/order/FinMonitoring.vue";


export default {
    components: {
        FinMonitoring,
        UseBonus,
        Earnings,
        otpBlock,
        Multiselect,
        VueTypeaheadBootstrap,
        AutocivilPromo,
        modalDocument,
        citySearch,
        countrySearch,
        modalSuccess,
        animationModal,
        ModalDeclaredSuccess,
        ModalGetAllPaymentWays,
        ModalGetInvoiceOrder,
        ModalGetInstantLoan,
        ModalGetMonoPayParts,
        ChoiceOtpSendingDirection,
        modalResponseStatus,
        modalResponseConfirmStatus,
    },
    mixins: [otpFunc],
    data: function () {
        return {
            showFinMonitoringModal: false,
            isAgreeFinMonitoring: false,
            otpFlags:{
              otpStatus: true, // true - Включить отправку ОТР - кода
              confirmOtpStatus: false, // true - OTP код проверен успешно
              sentOtpPass: false, // true - OTP код был отправлен mail/viber/sms
              sendingOtpError: false, // true - при отправке ОТР кода произошла ошибка
              submitOtpCheck: false, // true - была нажата кнопка отправки ОТР кода на проверку
              otpCodeValidate: true, // статус проверки ОТР кода true - прошел проверку, false - код неверный
              pandingOtpSending: false, // флаг для отображения прелоадера при отправке ОТР кода
              pandingOtpCheck: false, // флаг для отображения прелоадера при отправке ОТР кода на проверку
            },
            earnings: 0,
            useBonuses: false,
            dataIsLoadEarning: false,
            showEarnings: false,
            bonusSystemValue: 0,
            startOrderDateDisabled: true,
            startOrderDate: null,
            optionsStartOrderDate: {
                format: 'DD.MM.YYYY',
                useCurrent: false,
                minDate: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()+1),
                maxDate: new Date(new Date().getFullYear(), new Date().getMonth(), (new Date().getDate()+this.$store.getters.GREENCARD_INFO_OFFER.dateFromMax)),
                locale: 'uk',
                icons: {
                    previous: 'icon arrow-point-to-left',
                    next: 'icon arrow-point-to-right',
                },
            },
            enableEditform: false,
            LetterMaskError: false,
            showPopularCountries:false,
            confirmContractStatus: false,
            offerInfo: null,
            carBrands:[],
            carModels:[],
            contractId:false,
            orderId: false,
            dataResponse: null,
            dataProductType: 'greencard',
            car: {
                vin: null,
                year: null,
                brand: null,
                model: null,
                number: null,
                city: null,
                withoutVin: false
            },
            styleSel: {
                border: 'none',
            },
            searchInfo: null,
            showOtp: false,
            elSelectStatus:true,
            createTicketInfo: false,
            createUserInfo: false,
            submitCheck: false,
            personalCheck: false,
            isLegalType: false,
            cityFrom: null,
            carType:{},
            carTypes: [{"name":"Легкові автомобілі","group":"CAR", "external_id":"A",
                "engines":[{"name":"до 1600 см3","value":"A1"},
                    {"name":"1601-2000 см3","value":"A2"},
                    {"name":"2001-3000 см3","value":"A3"},
                    {"name":"более 3001 см3","value":"A4"}], "types":[]},
                {"name":"Автобус","group":"BUS", "external_id":"E", "engines":[],
                    "types":[{"name":"менше 20 осіб","value":"E1"},
                        {"name":"більше 20 осіб","value":"E2"}]},
                {"name":"Вантажні автомобілі","group":"CARGO", "external_id":"C", "engines":[],
                    "types":[{"name":"менше 2-х тонн","value":"C1"},
                        {"name":"більше 2-х тонн","value":"C2"}]},
                {"name":"Причіп, Трейлер","group":"TRAILER","engine":null, "external_id":"F", "engines":[],
                    "types":[{"name":"До вантажного авто","value":"F"},
                        {"name":"До легкового авто","value":"F"}]},
                {"name":"Мотоцикли та моторолери","group":"BIKE", "external_id":"B",
                    "engines":[{"name":"300 см3 або менше","value":"B1"},
                        {"name":"більше 300 см3","value":"B2"}], "types":[]}
            ],
            periodOptions: [
                {"id": 15, "name" : "15 д" },
                {"id": 1, "name" : "1 міс" },
                {"id": 2, "name" : "2 міс" },
                {"id": 3, "name" : "3 міс" },
                {"id": 4, "name" : "4 міс" },
                {"id": 5, "name" : "5 міс" },
                {"id": 6, "name" : "6 міс" },
                {"id": 7, "name" : "7 міс" },
                {"id": 8, "name" : "8 міс" },
                {"id": 9, "name" : "9 міс" },
                {"id": 10, "name" : "10 міс" },
                {"id": 11, "name" : "11 міс" },
                {"id": 12, "name" : "12 міс" },
            ],
            optionsStartDate: {
                format: 'DD.MM.YYYY',
                useCurrent: false,
                minDate: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
                locale: 'uk',
                icons: {
                    previous: 'icon arrow-point-to-left',
                    next: 'icon arrow-point-to-right',
                    time: "fa fa-clock-o",
                    date: "fa fa-calendar",
                },
            },
            order:{
                startDate: null,
                country: null,
                periodOption:null,
                info: {
                    identificationCode: null,
                    withoutIdentificationCode:false,
                    withoutDocuments:false,
                    name: null,
                    surname: null,
                    dateBirth: null,
                    phone: null,
                    mail: null,
                    city: null,
                    street: null,
                    house: null,
                    apartment: null,
                    internationalPassport: null,


                    documentType: null,
                    passportSerial: null,
                    passportNumber: null,
                    passportCompany: null,
                    passportDate: null,
                    documentation: {
                        //TODO текущее значение нобходимо будет брать с словаря(api)
                        type: 'DOCUMENT_PASSPORT',
                        serial: null,
                        number: null,
                        issuedBy: null,
                        dateOfIssue: null,
                        endDateOfIssue: null,
                        dateOfIssueError: false,
                    },
                }
            },
        }
    },

    validations: {
        periodOptionStatus:false,
    },
    created() {
        this.fetchOfferInfo(this.$store.getters.GREENCARD_INFO_OFFER);
        this.fetchSearchInfo(this.$store.getters.GREENCARD_SEARCH_PARAMS);
        this.fetchOrder(this.$store.getters.GREENCARD_FULL_ORDER);
        this.fetchInfoCar(this.$store.getters.GREENCARD_INFO_CAR);
        this.fetchUserInfo(this.$store.getters.GREENCARD_INFO_USER);
        this.isLegalType = (this.order.userType.id == 2);
        this.confirmContractStatus = this.fullInfo?.confirmContractStatus;

        this.startOrderDate = moment(this.$store.getters.GREENCARD_INFO_OFFER.startDate,'DD/MM/YYYY');

    },

    computed: {
        fullInfo() {
          return this.$store.state.GreenCardInsuranceCompanyStore.fullOrderInfo;
        },
        cityStore() {
            return this.car.city
        },
        countryStore() {
            if(this.dataCountry){
                return this.dataCountry
            }
        },
        getParentUserId() {
            return this.$store.getters.USER_INFO.parentId ?? this.$store.getters.USER.id;
        }
    },

    methods: {
        changestartOrderDate(){
            if(this.confirmContractStatus)
                this.startOrderDateDisabled = true;
            this.startOrderDateDisabled = false;
        },
        showAllOrderPaymentWays() {
            const price = this.order.fullPrice ?? this.order.price;
            this.$refs.modalGetAllPaymentWays.open(this.order.orderId, price);
        },
        showModalGetInvoiceOrder() {
            const price = this.order.fullPrice ?? this.order.price;
            this.$refs.modalGetInvoiceOrder.open(this.orderId, price);
        },
        showModalGetInstantLoan() {
            const price = this.order.fullPrice ?? this.order.price;
            this.$refs.modalGetInstantLoan.open(this.orderId, price);
        },
        showModalGetMonoPayParts() {
            const price = this.order.fullPrice ?? this.order.price;
            this.$refs.showModalGetMonoPayParts.open(this.orderId, price, []);
        },
        showModalGetInvoiceLegal: async function() {
            const price = this.order.fullPrice ?? this.order.price;

            let orderWithData = await showOrder(this.orderId);
            const mtsbuCode = orderWithData.data.data?.mtsbuCode ?? null;

            let invoiceLegalData = {
                company: this.order.info?.name,
                code: this.order.info?.erdpyCode ?? '',
                price: price,
                mtsbuCode: mtsbuCode,
            }

            let response = await getPaymentInvoice(this.order.orderId, price, 'legal-entity-insurance');

            let params = new URLSearchParams(invoiceLegalData).toString()
            downloadInvoiceLegal(this.getParentUserId, this.order.orderId, params)
        },
        formatDate(date) { // функция сделана для Apple, формат датапикера меняется...
            const formats = ['DD.MM.YYYY', 'DD-MM-YYYY', 'ddd MMM DD YYYY HH:mm:ss ZZ'];
            const momentDate = moment(date, formats, true);
            if (momentDate.isValid()) {
                return momentDate.format('DD-MM-YYYY');
            } else {
                // Если дата не может быть распознана, возвращаем исходное значение
                return date;
            }
        },
        confirmFinMonitoring() {
            setTimeout(() => {
                this.isAgreeFinMonitoring = true;
                this.makeContract('make');
            }, 1500);
        },
        checkFinMonitoring() {
            if (this.order.price >= 40000 && !this.isAgreeFinMonitoring) {
                this.showFinMonitoringModal = true;

                return true;
            }

            return false;
        },
        makeContract(type) {
            if (this.checkFinMonitoring()) {
                return;
            }

            this.submitCheck = true;
            if(this.personalCheck){
              this.startOrderDateDisabled = true;
              this.showAnimateModal();
              this.offerInfo.startDate = this.formatDate(this.startOrderDate);
              this.searchInfo.startDate = this.offerInfo.startDate;
              let offerInfo = this.offerInfo;
              let searchInfo = this.searchInfo;
              let userInfo = this.info;
              let carInfo = this.car;

              let params = {
                type: type,
                statusId: null,
              };

              this.$store.commit('SET_GREENCARD_INFO_USER', userInfo);
              this.$store.commit('SET_GREENCARD_INFO_CAR', carInfo);

              orderGreenCard(offerInfo, searchInfo, userInfo, carInfo, this.order.orderId, params, this.bonusSystemValue).then((response) => {
                $('#modalPayAnimation').modal('hide');
                if (response && response.data.count >= 1) {
                  //let fullInfo =  Object.assign({},this.fullInfo);
                  this.offerInfo.confirmContractStatus = true;
                  this.offerInfo.invoiceLink = response.data.data[0]?.mtsbuLink;

                  this.confirmContractStatus = true;
                  this.orderId = response.data.data[0].id;
                  this.$store.commit('SET_GREENCARD_FULL_ORDER', response.data.data[0].id);
                  let mtsbuLink = response.data.data[0]?.mtsbuLink;
                  this.invoiceLink = response.data.data[0]?.mtsbuLink;
                  if (mtsbuLink) {
                    this.$refs.modalDeclaredSuccess.open(mtsbuLink);
                  } else {
                    this.$alert("Зелена картка заявлена", '', 'error');
                  }
                  return true;
                }else if(response && response.data.message){
                  //let errorMessage = resp.data.message;
                  console.log('resp.data.message',response.data.message);
                  this.dataResponse = response.data.message;//this.errorMassage(errorMessage);
                  $('#modalResponseStatus').modal('show');

                }
                //this.$alert("Зелена картка не заявлена, перевірте будь ласка дані і спробуйте ще!", '', 'error', {width: '300px'});
              }).catch(error => {
                $('#modalPayAnimation').modal('hide');
                if (error?.response?.data?.data?.orderId[0].includes('недостатньо коштів')) {
                  this.$alert(error?.response?.data?.data?.orderId[0], '', 'error');
                } else {
                  this.$alert("Зелена картка не підписана", '', 'error');
                }
              });
            }
        },
        confirmContract() {
            this.showAnimateModal();

            let data = {
                orderId: this.orderId,
                bonusSystemValue: this.bonusSystemValue
            };
            // prolongOrderId
            if (this.offerInfo.prolongOrderId) {
                data.prolongOrderId = this.offerInfo.prolongOrderId;
            }

            contractGreenCard(data).then((response) => {
                $('#modalPayAnimation').modal('hide');
                if (response?.data?.status === 'success' && !!response.data.data[0].contractId) {
                    $('#modalFlightSuccess').modal('show');
                    this.$store.commit('SET_GREENCARD_CONTRACT_ID', response.data.data[0].contractId);
                    this.contractId = response.data.data[0].contractId;
                    //this.$router.push({name: "history"});
                    return true;
                }

                this.$alert("Зелена Картка не оформлена", '', 'error');
            }).catch(error => {
              //console.log('error?.response?.data',error?.response?.data);
                if (error?.response?.data.data.length > 0 && error?.response?.data?.data?.orderId[0].includes('недостатньо коштів')) {
                    this.$alert(error?.response?.data?.data?.orderId[0], '', 'error');
                }else if(error?.response?.data?.message){
                  //console.log('error?.response?.data?.message',error?.response?.data?.message);
                  this.dataResponse = error?.response?.data?.message;
                  $('#modalResponseConfirmStatus').modal('show');
                } else {
                    this.$alert("Зелена Картка не оформлена", '', 'error');
                }

                $('#modalPayAnimation').modal('hide');
            });

        },
        saveChange(){
            this.submitCheck = true;
            if (this.$v.$invalid) {
                this.$v.$touch()
                return
            }
            changeOrderGreenCard(this.order).then((response) => {
                this.$store.commit('SET_GREENCARD_CHANGE_ORDER', this.order);
                this.$router.go();
            }).catch((error) => {
                this.$alert('Щось пішло не так. Перевірте заповнені поля.')
            });
        },
        fetchOrder(data){
            if(data){
                this.order = data;
                this.orderId = this.order.orderId;
            }
        },
        fetchSearchInfo(data){
            if(data){
                this.searchInfo = data;
            }
        },
        fetchOfferInfo(data){
            if(data){
                this.offerInfo = data;
            }
        },
        fetchInfoCar(data){
            if(data){
                this.car = data;
            }
        },
        fetchUserInfo(data){
            if(data){
                this.info = data;
            }
        },
        downloadContract() {
            $('#modalFlightSuccess').modal('hide');
            downloadOrder(this.$store.getters.USER.id, this.order.orderId);
        },
        showDraft() {
            downloadOrder(this.$store.getters.USER.id, this.order.orderId);
        },
        citySelect(data) {
            this.car.city = data.city;
        },
        countrySelect(data) {
            this.country = data.country;
        },
        showAnimateModal() {
            $("#modalPayAnimation").modal({
                backdrop: "static",
                keyboard: true,
                show: true,
            });

            let i = 0;

            if (i == 0) {
                i = 1;
                let width = 1;
                let id = setInterval(frame, 200);

                function frame() {
                    if (width >= 99) {
                        clearInterval(id);
                        i = 0;
                    } else {
                        width += 2;
                        $(".results_anime_percent-text").text(`${width}%`);
                        $(".results_anime_line-scale").css({width: `${width}%`});
                    }
                }
            }
        },
        checkCarType(){
            /*if(this.registrationType !== 3){
                if(this.carType !== undefined){
                    if(this.carType.group === "CARGO" || this.carType.group === "TRAILER"){
                        this.euroDisabled = true;
                        this.euroChecked = false;
                        $('.params-city-status').removeClass('disabled');
                    }else{
                        this.euroDisabled = false;
                    }
                }
            }*/
        },
        LetterMask(e){
            let char = String.fromCharCode(e.keyCode); // Get the character
            if(/^[A-Za-z ]+$/.test(char)) {
                this.LetterMaskError = false;
                return true;
            } // Match with regex
            else {
                this.LetterMaskError = true;
                e.preventDefault();
            }
        },
        isInvalid(val) {
            return val == null && this.submitForm;
        },
        recalculateEarnings() {
            this.dataIsLoadEarning = true;
            let params = this.$store.state.GreenCardInsuranceCompanyStore.requestData;
            greenCardCompaniesList(
                params.country.id,
                params.carType.external_id,
                params.userType.id,
                params.startDate,
                params.periodOption.id
            ).then((response) => {
                if (response?.data?.status === 'success') {
                    let offer = this.$_.find(response?.data?.data, (item) => {
                        return item.company.id === this.order.company.id;
                    })
                    this.earnings = offer.earnings
                    this.dataIsLoadEarning = false;
                    return true;
                }
            }).catch((error) => {
                this.earnings = 0;
                this.dataIsLoadEarning = false;
            });
        },
    },
    mounted() {
        this.recalculateEarnings()
    }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
.osago {
    overflow-x: unset;
}
</style>
