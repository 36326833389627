export function list(params = null) {
    return axios({
        url: `/api/knowledge-base-articles`,
        method: 'post',
        data: params,
    });
}

export function show(id) {
    return axios({
        url: `/api/knowledge-base-articles/show`,
        method: 'get',
        params: {
            id: id
        }
    });
}

export function setArticleReaction(params = null) {
    return axios({
        url: `/api/knowledge-base-articles/reaction`,
        method: 'post',
        data: params,
    });
}

export function categoriesList(params = null) {
    return axios({
        url: `/api/knowledge-base-categories`,
        method: 'get',
        params: params,
    });
}

export function showCategory(params) {
    return axios({
        url: `/api/knowledge-base-categories/show`,
        method: 'get',
        params: params
    });
}
