<template>
    <div class="history">
        <filter-block :is-load-xml="condition.idLoadXml"
                              @download-xls="downloadXls()"
                              :is-load-data="condition.isLoadTable"
                              :articles="knowledgeBaseArticles"
                              :categories-list="knowledgeBaseCategories"
                              :top-search-queries="knowledgeBaseTopSearchQueries"
                              @init-articles-list="initList(2)"
                              ref="filter"></filter-block>

        <div v-if="showMain" class="knowledge-articles kb-block">
            <div class="kb-block-title knowledge-articles-title">
                Популярні статті
            </div>
            <div class="knowledge-articles-cards">
                <div class="col-lg-4" v-for="article in knowledgeBaseArticles" :key="article.id">
                    <preview
                             :id="article.id"
                             :key="article.id"
                             :previewImage="article.previewImageUrl"
                             :isDisableForPartner="article.isDisableForPartner"
                             :status-id="article.statusId"
                             :title="article.title"
                             :tags-list="article.tags"
                             >
                        {{article}}
                    </preview>
<!--                    <div class="knowledge-articles-card">-->
<!--                        <div class="ka-card-tags">-->
<!--                            <div class="ka-tags-list">-->
<!--                                <div v-for="tag in article.tags" class="ka-tags-item">{{ tag.tag_name }}</div>-->
<!--                                <div v-if="article.tags.length" class="ka-tags-item">+1</div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                        <div class="ka-card-img">-->
<!--                            <div class="preview" :style="'background-image:url('+ article.previewImageUrl +')'"></div>-->
<!--&lt;!&ndash;                            <img class="preview" :src="article.previewImageUrl"/>&ndash;&gt;-->
<!--                        </div>-->
<!--                        <router-link class="ka-card-info" :to="{ name: 'knowledgeBaseShow', params: { id: article.id } }">-->
<!--                            {{ article.title }}-->
<!--                        </router-link>-->
<!--                    </div>-->
                </div>
            </div>
        </div>

        <div v-if="showMain" class="categories-block kb-block">
            <div class="categories-title kb-block-title">Категорії</div>
            <div class="categories-items">
                <div class="col-lg-3 " v-for="category in knowledgeBaseCategories" :key="category.id">
                    <a :href="'/knowledge-base-categories/' + category.id" class="category-item">
                        <div class="category-icon">
                            <img :src="category.categoryImageUrl"/>
                        </div>
                        <div class="category-name" >
                            <div>
                                {{ category.name }}
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </div>

        <div v-if="showArticleList" class="">
            <div class="kb-go-main">
                <div class="kb-go-back" @click="initData(1)">
                    <svg width="19" height="12" viewBox="0 0 19 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M17 4.5H1M1 4.5L4.5 1M1 4.5L4.5 8" stroke="#009BEB"/>
                    </svg>
                    <span>Повернутися до головної</span>
                </div>
            </div>
            <div class="kb-go-main">
<!--                <router-link class="kb-go-back" :to="{ name: 'knowledgeBase' }">-->
<!--                    <svg width="19" height="12" viewBox="0 0 19 12" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                        <path d="M17 4.5H1M1 4.5L4.5 1M1 4.5L4.5 8" stroke="#009BEB"/>-->
<!--                    </svg>-->
<!--                    <span>Повернутися до головної</span>-->
<!--                </router-link>-->
<!--                <router-link class="go-back" :to="{ name: 'knowledgeBase' }">-->
<!--                    <svg width="17" height="9" viewBox="0 0 17 9" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                        <path d="M17 4.5H1M1 4.5L4.5 1M1 4.5L4.5 8" stroke="#009BEB"/>-->
<!--                    </svg>-->
<!--                    <span>Повернутися до головної</span>-->
<!--                </router-link>-->
            </div>
            <knowledge-base-articles-list
                ref="articlesList"
                @init-articles-list="initList(2)"
                @filter-status-wrap="filterStatusWrap()"
                :count-articles="countArticles"
                :is-load-data="condition.isLoadTable"
                :is-mobile="this.condition.isMobile"
                :articles="knowledgeBaseArticles">
            </knowledge-base-articles-list>
            <div style="text-align: center">
                <pagination v-show="total > 0" :total="total"
                            :page.sync="listQuery.page"
                            :limit.sync="listQuery.limit" @pagination="initList(2)" />
            </div>
        </div>

    </div>
</template>


<script>
    import Vue from "vue";
    import {orderMixin} from '@/mixin'
    import Pagination from '@/components/Pagination';
    import {list as listOrders, prolongation as listProlongation,  downloadExcel} from "@/api/order";
    import { list as articleList } from '@/api/knowledgeBase';
    import { default as knowledgeBaseArticlesList } from "@/views/knowledge-base/list";
    import { default as filterBlock } from "@/views/knowledge-base/filterBlock";
    import preview from './components/preview';

    const FileDownload = require('js-file-download');

    export default {
        mixins: [orderMixin],
        components: {
            knowledgeBaseArticlesList,
            Pagination,
            filterBlock,
            preview,
        },
        data: function () {
            return {
                knowledgeBaseArticles: [],
                knowledgeBaseCategories: [],
                knowledgeBaseTopSearchQueries: [],
                total: 1,
                countArticles: 0,
                showArticleList: false,
                showMain: true,
                mobileWith: 992,
                condition: {
                    isLoadTable: true,
                    isLoadXml: false,
                    isMobile: false,
                },
                listQuery: {
                    page: 1,
                    limit: 9,
                }
            }
        },
        created: function() {
            window.addEventListener("resize", this.checkWindowResize);
            this.checkIsMobile();
        },
        mounted: function() { // The hook. Here, Vue has already worked its magic.
            this.initData(1);
        },
        computed:{
          orderStatuses: function () {
            return this.$store.getters.ORDER_STATUSES;
          },
        },
        methods: {
            checkIsMobile: function() {
                return this.condition.isMobile = window.innerWidth < this.mobileWith;
            },
            checkWindowResize: function() {
                this.checkIsMobile()
            },
            filterStatusWrap: function() {
                this.$refs.filter.filterStatusWrap(true);
            },
            getQuery: function () {
                return {...this.$refs.filter.listQuery, /*...this.$refs.articlesList.listQuery,*/ ...this.listQuery};
            },
            resetQueryParams(query) {
                this.$refs.filter.listQuery.searchText = null;
                this.$refs.filter.listQuery.categoryId = null;
            },
            initData: async function (filterButtonEvent) {
                let query = {};
                let params = {};

                if (filterButtonEvent === 1) {
                    this.listQuery.page = 1;
                    this.listQuery.limit = 9;
                    this.listQuery.sortBy = 'views';
                }

                let response;
                this.showArticleList = false;
                this.showMain = true;
                this.knowledgeBaseArticles = [];
                this.condition.isLoadTable = true;
                this.resetQueryParams(query);

                query = this.getQuery();
                params = {...params, ...query};

                response = await articleList(params);
                const { data } = response;

                this.countArticles = data.data?.countArticles ?? data.data?.total;
                this.knowledgeBaseArticles = data.data.knowledgeBaseArticles.items;
                this.knowledgeBaseCategories = data.data.knowledgeBaseCategories;
                this.knowledgeBaseTopSearchQueries = data.data.knowledgeBaseSearchQueries;
                this.total = data.data.knowledgeBaseArticles?.total;
                this.condition.isLoadTable = false;

            },
            initList: async function (requestType, filterButtonEvent, fastSearch = false) {
                let query = {};
                let params = {
                    "requestType": "articles",
                };
                query = this.getQuery();

                this.showArticleList = true;
                this.showMain = false;
                this.knowledgeBaseArticles = [];
                this.total = 0;
                this.condition.isLoadTable = true;

                params = {...params, ...query};

                let response;
                response = await articleList(params);
                const { data } = response;
                this.knowledgeBaseArticles = data.data.knowledgeBaseArticles.items;
                this.total = data.data.knowledgeBaseArticles?.total;
                this.condition.isLoadTable = false;
            },
            calcWidth(item) {}
        },

        destroyed() {
            window.removeEventListener("resize", this.checkWindowResize);
        },
    }
</script>
