const state = {
  types: [{
    category: 'Мотоцикл і моторолер',
    list: [{ name: '300 см3 чи менше', id: 'A1' },
      { name: 'більше 300 см3', id: 'A2' }],
  }, {
    category: 'Легковий автомобіль',
      list: [
          {name: 'до 1600 см3', id: 'B1'},
          {name: '1601-2000 см3', id: 'B2'},
          {name: '2001-3000 см3', id: 'B3'},
          {name: 'більше 3001 см3', id: 'B4'},
          {name: 'електромобіль', id: 'B5'}
      ],
  }, {
    category: 'Вантажний автомобіль',
    list: [{ name: 'Менше 2-х тонн', id: 'C1' },
      { name: 'Больше 2-х тонн', id: 'C2' }],
  }, {
    category: 'Автобус',
    list: [{ name: 'Менше 20 человек', id: 'D1' },
      { name: 'Больше 20 человек', id: 'D2' }],
  }, {
    category: 'Причіп, Трейлер',
    list: [{ name: 'До вантажного авто', id: 'E' },
      { name: 'До легковому авто', id: 'F' }],
  },
  ],
  list: [
    { name: 'Всі типи', id: null },
    { name: '300 см3 чи менше (Мотоцикл і моторолер)', id: 'A1' },
    { name: 'більше 300 см3 (Мотоцикл і моторолер)', id: 'A2' },
    { name: 'до 1600 см3 (Легковой автомобиль)', id: 'B1' },
    { name: '1601-2000 см3 (Легковой автомобиль)', id: 'B2' },
    { name: '2001-3000 см3 (Легковой автомобиль)', id: 'B3' },
    { name: 'Легковий електромобіль', id: 'B5' },
    { name: 'більше 3001 см3 (Легковой автомобиль)', id: 'B4' },
    { name: 'Легковий електромобіль', id: 'B5' },
    { name: 'менше 2-х тонн (Вантажний автомобіль)', id: 'C1' },
    { name: 'більше 2-х тонн (Вантажний автомобіль)', id: 'C2' },
    { name: 'менше 20 осіб (Автобус)', id: 'D1' },
    { name: 'більше 20 осіб (Автобус)', id: 'D2' },
    { name: 'До вантажного авто (Прицеп)', id: 'E' },
    { name: 'До легкового авто (Прицеп)', id: 'F' },
  ],
    groups:[
        {"name": "Легкові автомобілі", "group": "CAR", "external_id":"A"},
        {"name": "Автобус", "group": "BUS",  "external_id":"E"},
        {"name": "Вантажні автомобілі", "group": "CARGO", "external_id":"C"},
        {"name": "Причіп, Трейлер", "group": "TRAILER", "external_id":"F", "type": "До вантажного авто"},
        {"name": "Мотоцикли та моторолери", "group": "BIKE", "external_id":"B"}
    ]
};
//TODO Заменить на спарвочник


const getters = {
    AUTO_CATEGORIES_LIST: state => state.list,
    AUTO_TYPES_LIST: state => state.groups
};

export default {
  state,
  getters
};

