<template>
  <div v-if="showPopup" class="popup-overlay">
    <div class="popup-content">
      <h3>Шановні партнери!</h3>
      <p>Прохання ВСІМ пройти опитування:</p>
      <p>Укладання договору співпраці з UKASKO для підготовки до змін в ЗУ "Про страхування", який починає діяти з 01.01.2025 року</p>
      <div class="row bottom-block">
        <div class="col pr-10" align="right">
          <button :href="link" target="_blank" class="btn btn-orange" style="display: block" @click="handleLinkClick()">
            Пройти опитування
          </button>
        </div>
        <div class="col pl-10">
          <button class="btn btn-danger" @click="handleAlreadyClicked" style="display: block">Вже проходив</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueCookie from 'vue-cookie';

export default {
  name: "ModalCanvass",
  props: {
    link: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      showPopup: false,
      linkClicked: false
    };
  },
  mounted() {
    if(this.isMainUser){
      this.showPopup = !VueCookie.get("popupLinkVisited");
    }
  },
  methods: {
    handleLinkClick() {
      this.linkClicked = true;
      this.showPopup = false;
      VueCookie.set("popupLinkVisited", true, { expires: "7D" });
      window.open(this.link, '_blank');
    },
    handleAlreadyClicked() {
      this.linkClicked = true;
      this.showPopup = false;
      VueCookie.set("popupLinkVisited", true, { expires: "7D" });
    },
    isMainUser() {
      return !!! this.$store.getters.USER?.user_parent_id;
    },
  }
};
</script>

<style scoped>
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.popup-button {
  display: inline-block;
  margin: 10px 0;
  padding: 10px 20px;
  background: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  text-decoration: none;
  cursor: pointer;
}

.popup-button:hover {
  background: #0056b3;
}

.close-button {
  display: inline-block;
  margin: 10px 0;
  padding: 10px 20px;
  background: #6c757d;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.close-button:disabled {
  background: #cccccc;
  cursor: not-allowed;
}
</style>
