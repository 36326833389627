<template>
    <div @click="show()" class="knowledge-articles-card" ref="knowledgeArticleCard" @resize="updateVisibleTags">
        <div class="ka-card-tags">
            <div class="ka-tags-list">
                <div v-for="tag in visibleTags" class="ka-tags-item">{{ tag.tag_name }}</div>
<!--                <div v-if="tagsList.length" class="ka-tags-item">+1</div>-->
<!--                <div v-if="hiddenTagsCount > 0" class="ka-tags-item tag more-tags">+{{ hiddenTagsCount }}</div>-->
                <el-popover
                    v-if="hiddenTagsCount > 0"
                    placement="top-start"
                    trigger="hover"
                >
                    <el-button class="ka-tags-item tag more-tags" slot="reference">+{{ hiddenTagsCount }}</el-button>
                    <div class="ka-popover-tags">
                        <div v-for="tag in hiddenTags" class="ka-popover-tag">{{ tag.tag_name }}</div>
                    </div>
                </el-popover>
            </div>
        </div>
        <div v-if="statusId == 0" class="draft-label">Чернетка</div>
        <div class="ka-card-img">
            <div class="preview" :style="'background-image:url('+ previewImage +')'"></div>
            <!--                            <img class="preview" :src="article.previewImageUrl"/>-->
        </div>
        <div class="ka-card-info">{{ title }}</div>
<!--        <router-link class="ka-card-info" :to="{ name: 'knowledgeBaseShow', params: { id: id } }">-->
<!--            {{ title }}-->
<!--        </router-link>-->
        <!--                        <a @click="show()" class="pa-card-info">{{ article.title }}</a>-->
    </div>
</template>

<script>

export default {
    props: {
        isDisableForPartner: {
            type: Boolean,
            default: () => {
                return false;
            }
        },
        statusId: {
            type: Number,
            default: () => {
                return null;
            }
        },
        id: {
            type: Number,
            default: () => {
                return null;
            }
        },
        title: {
            type: String,
            default: () => {
                return null;
            }
        },
        previewImage: {
            type: String,
            default: () => {
                return null;
            }
        },
        tagsList: {
            type: Array,
            default: () => {
                return [];
            }
        },
        description: {
            type: String,
            default: () => {
                return null;
            }
        },
    },
    data() {
        return {
            visibleTags: [],
            hiddenTags: [],
            hiddenTagsCount: 0,
        };
    },
    mounted() {
        this.updateVisibleTags();
        window.addEventListener("resize", this.updateVisibleTags);
    },
    beforeDestroy() {
        window.removeEventListener("resize", this.updateVisibleTags);
    },
    methods: {
        show: function() {
            this.$router.push({name: 'knowledgeBaseShow', params: {id: this.id}})
        },
        updateVisibleTags() {
            const cardWidth = this.$refs.knowledgeArticleCard.firstChild.clientWidth
            let visibleTags = [];
            let widthAccumulator = 0;
            const tagPadding = 8;

            this.tagsList.forEach((tag) => {
                const tagWidth = this.calculateTagWidth(tag);
                if (widthAccumulator + tagWidth + tagPadding <= cardWidth) {
                    visibleTags.push(tag);
                    widthAccumulator += tagWidth + tagPadding;
                }
            });

            this.visibleTags = visibleTags;
            const visibleTagsIds = visibleTags.map(i => i.id);

            this.hiddenTags = this.tagsList.filter(i => !visibleTagsIds.includes(i.id));
            this.hiddenTagsCount = this.tagsList.length - visibleTags.length;
        },
        calculateTagWidth(tag) {
            // Создаем временный элемент для расчета ширины тега
            const span = document.createElement("span");
            span.style.visibility = "hidden";
            span.className = "ka-tags-item";
            span.innerText = tag;
            document.body.appendChild(span);
            const width = span.offsetWidth;
            document.body.removeChild(span);
            return width;
        },
    }
}
</script>

<style lang="scss" scoped>

.article__show-more {
    display: block;
    width: 100%;
    padding: 7px 15px;
    border-radius: 3px;
    margin: auto auto 0;
    transition: all .2s;
    border: 1px solid #FFAD3D;
    color: #FFAD3D;
    font-weight: 700;
    font-size: 16px;
    text-align: center;

    &:hover {
        color: #fff;
        background-color: #FFAD3D;
        text-decoration: none;
    }
}
.article {
    background: #fff;
    height: 100%;
    display: flex;
    border-radius: 10px;
    flex-direction: column;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15);
    position: relative;

    .draft-label{
        background: #4a87d5ba;
        color: #fff;
        padding: 5px 25px;
        position: absolute;
    }

    .title {
        font-size: 18px;
        color: #24282c;
        font-weight: 700;
        text-align: left;
        margin: 10px 0;
    }
    .info {
        padding: 20px;
        font-size: 15px;
        color: #999da6;
        text-align: left;
        margin: 0;
        height: 100%;
        display: flex;
        flex-direction: column;

        .descr{
            word-break: break-all;
            p{
                word-break: break-all;
            }
        }

        .title h3 {
            font-size: 18px;
            color: #24282c;
            font-weight: 700;
            text-align: left;
            margin: 10px 0;
        }

        .short-content {
            color: #999da6;
            text-align: left;
            margin: 0 0 25px;
            font-size: 11pt;
            font-family: Arial;
            font-variant-numeric: normal;
            font-variant-east-asian: normal;
            vertical-align: baseline;
            white-space: pre-wrap;
        }
    }
}

.article__alert{
    position: absolute;
    top: 9px;
    left: 9px;
    background: #FFFFFF;
    border-radius: 30px;
    color: #999DA6;
    font-weight: bold;
    font-size: 13px;
    line-height: 19px;
    padding: 2px 8px;
    display: flex;
    align-items: center;
    svg{
        margin-right: 6px;
    }
    @media screen and (max-width: 1200px){
        font-size: 11px;
    }
    @media screen and (max-width: 359px){
        font-size: 9px;
    }
}

.truncate-overflow {
    --lh: 1.4rem;
    line-height: var(--lh);
    --max-lines: 3;
    position: relative;
    max-height: calc(var(--lh) * var(--max-lines));
    overflow: hidden;
    padding-right: 1rem; /* space for ellipsis */
}
.truncate-overflow::before {
    position: absolute;
    content: "...";
    inset-block-end: 0; /* "bottom" */
    inset-inline-end: 0; /* "right" */
}
.truncate-overflow::after {
    content: "";
    position: absolute;
    inset-inline-end: 0; /* "right" */
    width: 1rem;
    height: 1rem;
    background: white;
}

.preview {
    min-height: 170px;
    height: 170px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 5px 5px 0 0;
    overflow: hidden;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;

    img {
        height: 100%;
        width: 100%;
        -o-object-fit: cover;
        object-fit: cover;
        -o-object-position: right bottom;
        object-position: right bottom;
    }
}

</style>
