<template>
    <div class="container kb-list-container">
        <div class="kb-result-label">
            <p>Результати пошуку</p>
        </div>
        <div v-if="isLoadData" class="row">
            <preview-loader v-for="index in 3" :key="index"></preview-loader>
        </div>
        <div class="knowledge-articles kb-block" v-else-if="articlesList && articlesList.length > 0">
            <div class="knowledge-articles-cards">
                <div class="col-lg-4" v-for="article in articlesList" :key="article.id">
                    <preview
                        :id="article.id"
                        :key="article.id"
                        :previewImage="article.previewImageUrl"
                        :isDisableForPartner="article.isDisableForPartner"
                        :status-id="article.statusId"
                        :title="article.title"
                        :tags-list="article.tags"
                    >
                        {{article}}
                    </preview>
                </div>
            </div>
<!--            <preview v-for="article in articlesList" :key="article.id"-->
<!--                     :preview="article.previewImageUrl"-->
<!--                     :isDisableForPartner="article.isDisableForPartner"-->
<!--                     :status-id="article.statusId"-->
<!--                     :id="article.id"-->
<!--                     :title="article.title"-->
<!--                     :description="article.description">-->
<!--                {{article}}-->
<!--            </preview>-->
        </div>
        <div v-else class="empty-result">
            <p>За вашим запитом нічого не знайдено</p>
        </div>
<!--        <div style="text-align: center">-->
<!--            <pagination v-show="total > 0" :total="total"-->
<!--                        :page.sync="listQuery.page"-->
<!--                        :limit.sync="listQuery.limit" @pagination="initList()" />-->
<!--        </div>-->
    </div>
</template>

<script>

import Pagination from '@/components/Pagination';
import preview from './components/preview';
import { list as articleList } from '@/api/knowledgeBase';
import { userViewedNews } from '@/api/news';
import previewLoader from './components/previewLoader';

export default {
    components: {
        previewLoader,
        preview,
        Pagination
    },
    props: {
        countProlongation: {
            type: Number,
            default: () => {
                return 0;
            }
        },
        isLoadData: {
            type: Boolean,
            default: () => {
                return true
            }
        },
        isMobile: {
            type: Boolean,
            default: () => {
                return true;
            }
        },
        articles: {
            type: Array,
            default: () => {
                return [{
                    statusId: null,
                    title: null,
                    content: null,
                }];
            },
        },
    },
    data: function () {
        return {
            total: 10,
            condition: {
                isLoadArticles: true,
            },
            listQuery: {
                page: 1,
                limit: 10,
            },
            articlesList: [],
            knowledgeBaseArticles: {},
        }
    },
    created:  function() {
        this.articlesList = this.articles;
    },
    mounted () {
      window.scrollTo(0, 0)
    },
    watch: {
        'articles': {
            handler: function (val, oldVal) {
                this.articlesList = val;
            },
            deep: true
        },
    },
    methods: {

    }
}
</script>
