export default {
    mounted(el) {
        el.addEventListener('input', () => {
            // Удаляем все символы, кроме цифр
            el.value = el.value.replace(/\D/g, '');
            // Генерируем событие input, чтобы обновить v-model
            console.log('el.value',el.value);
            el.dispatchEvent(new Event('input'));
        });
    }
};
