<template>
    <div class="mainsearch flight-search">
        <form @submit.prevent="submitHandler">
            <div class="form">
                <div class="row">
                    <div class="col-12">
                        <div class="mainsearch__heading">Інформація по рейсу</div>
                    </div>
                    <div class="col-12">
                        <div class="form-group privilage-group">
                            <label>Тип користувача <span class="required-icon"> *</span></label>
                            <div class="radiolist">
                                    <div class="radioitem" v-for="(item, index) in userTypeList" v-if="item.id < 3" :key="index">
                                    <input type="radio" name="userType" :id="`usertype-${index}`" :checked="userType.key === item.key">
                                    <label :for="`usertype-${index}`" @click="userType = item">{{item.value}}</label>
                                    <div class="check"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3">
                        <div class="form-group">
                            <label>Територія покриття</label>
                            <div class="city-container-item">
                                <countrySearch
                                :countrySelect='countrySelect'
                                v-bind:countryStore="this.countryStore"/>
                                <small class="error" v-if="!$v.country.required && submitCheck">Вкажіть територію покриття</small>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3">
                        <div class="form-group">
                            <label>Тип ТЗ (категорія)</label>
                            <multiselect
                                :class="{'form-control' : true, 'is-invalid' : isInvalid(this.carType)}"
                                v-model="carType"
                                :options="carTypes"
                                placeholder="Виберіть тип транспортного засобу"
                                :max-height="120"
                                @input="checkCarType"
                                :allow-empty="false"
                                label="name"></multiselect>

                        </div>
                    </div>
                    <div class="col-lg-3">
                        <div class="form-group">
                            <label>Початок дії</label>
                            <date-picker v-model="startDate" :config="optionsStartDate" :class="['form-control', 'date-input', {invalid: (!$v.startDate.required && submitCheck)}]" placeholder="Дати від’їзду"></date-picker>
                            <small class="error" v-if="!$v.startDate.required && submitCheck">Вкажіть дати від’їзду</small>
                        </div>
                    </div>
                    <div class="col-lg-3">
                        <div class="form-group">
                            <label>Період дії</label>
                            <multiselect
                                :class="['form-control', {invalid: (!$v.periodOptions.required && periodOptionStatus)}]"
                                v-model="periodOption"
                                :options="periodOptions"
                                placeholder="Виберіть період дії"
                                :max-height="120"
                                @input="checkPeriodOption"
                                :allow-empty="false"
                                :searchable="false"
                                label="name"></multiselect>
                            <small class="error" v-if="!$v.periodOptions.required && periodOptionStatus">Вкажіть період дії</small>

                        </div>
                    </div>



<!--                    <div class="col-lg-3">
                        <div class="form-group">
                            <label>Номер квитка</label>
                            <input v-model="ticketNumber" type="text" :class="['form-control', {invalid: (!$v.ticketNumber.required && submitCheck)}]" placeholder="Введіть номер квитка">
                            <small class="error" v-if="!$v.ticketNumber.required && submitCheck">Вкажіть номер квитка</small>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="form-group ticket-price">
                            <label>Вартість квитка</label>
                            <div>
                                <input v-model="ticketPrice" type="number" :class="['form-control', {invalid: (!$v.ticketPrice.required && submitCheck)}]" placeholder="Вартість квитка">
                                <small class="error" v-if="!$v.ticketPrice.required && submitCheck">Вкажіть вартість квитка</small>
                            </div>
                        </div>
                    </div>-->
                    <div class="col-lg-12 insurance-nav d-flex justify-content-center">
                        <button class="btn-blue-w" type="submit">
                            Показати результати
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
    import Multiselect from 'vue-multiselect'
    //import citySearch from '@/components/app/forms/CitySearch.vue'
    import countrySearch from "@/components/app/forms/CountrySearch";
    import {maxLength, minLength, minValue, numeric, required, requiredIf} from 'vuelidate/lib/validators';
    import { findCityById } from '@/api/dictionary';

    export default {
        components: {countrySearch, Multiselect},
        props: ['dataCountry',
                'dataCityFrom',
                'dataCarType',
                'dataUserType',
                'dataStartDate',
                'dataPeriodOption'
                ],
        data: function () {
            return {
                //TODO этот момент переписать, когда поменяем форму управления
                citiesOptions: [ {"id": 1,"name": "Київ","name_full_name_ua": "м. Київ, Україна", "zone":1},
                  {"id": 9,"name": "Дніпро","name_full_name_ua": "м. Дніпро, Дніпропетровська  обл., Україна", "zone":2},
                  {"id": 11,"name": "Львів","name_full_name_ua": "м. Львів, Львівська  обл., Україна", "zone":1},
                  {"id": 13,"name": "Одеса","name_full_name_ua": "м. Одеса, Одеська  обл., Україна", "zone":2},
                  {"id": 14,"name": "Харків","name_full_name_ua": "м. Харків, Харківська  обл., Україна", "zone":2},
                  {"id": 16,"name": "Запоріжжя","name_full_name_ua": "м. Запоріжжя, Запорізька  обл., Україна", "zone":3},
                  {"id": 17,"name": "Кривий Ріг","name_full_name_ua": "м. Кривий Ріг, Дніпропетровська  обл., Україна", "zone":3},
                  {"id": 21,"name": "Вінниця","name_full_name_ua": "м. Вінниця, Вінницька  обл., Україна", "zone":4},
                  {"id": 36,"name": "Маріуполь","name_full_name_ua": "м. Маріуполь, Донецька  обл., Україна", "zone":4},
                  {"id": 41,"name": "Полтава","name_full_name_ua": "м. Полтава, Полтавська  обл., Україна", "zone":4}],
                carType: {"name":"Легкові автомобілі","group":"CAR", "external_id":"A",
                    "engines":[{"name":"до 1600 см3","value":"A"},
                        {"name":"1601-2000 см3","value":"A21"},
                        {"name":"2001-3000 см3","value":"A3"},
                        {"name":"более 3001 см3","value":"A4"}], "types":[]},
                carTypes: [{"name":"Легкові автомобілі","group":"CAR", "external_id":"A",
                    "engines":[{"name":"до 1600 см3","value":"A1"},
                        {"name":"1601-2000 см3","value":"A2"},
                        {"name":"2001-3000 см3","value":"A3"},
                        {"name":"более 3001 см3","value":"A4"}], "types":[]},
                    {"name":"Автобус","group":"BUS", "external_id":"E", "engines":[],
                        "types":[{"name":"менше 20 осіб","value":"E1"},
                            {"name":"більше 20 осіб","value":"E2"}]},
                    {"name":"Вантажні автомобілі","group":"CARGO", "external_id":"C", "engines":[],
                        "types":[{"name":"менше 2-х тонн","value":"C1"},
                            {"name":"більше 2-х тонн","value":"C2"}]},
                    {"name":"Причіп, Трейлер","group":"TRAILER","engine":null, "external_id":"F", "engines":[],
                        "types":[{"name":"До вантажного авто","value":"F"},
                            {"name":"До легкового авто","value":"F"}]},
                    {"name":"Мотоцикли та моторолери","group":"BIKE", "external_id":"B",
                        "engines":[{"name":"300 см3 або менше","value":"B1"},
                            {"name":"більше 300 см3","value":"B2"}], "types":[]}
                    ],
                periodOption: {"id": 12, "name" : "12 міс" },
                periodOptions: [
                    {"id": 15, "name" : "15 д" },
                    {"id": 21, "name" : "21 д" },
                    {"id": 1, "name" : "1 міс" },
                    {"id": 2, "name" : "2 міс" },
                    {"id": 3, "name" : "3 міс" },
                    {"id": 4, "name" : "4 міс" },
                    {"id": 5, "name" : "5 міс" },
                    {"id": 6, "name" : "6 міс" },
                    {"id": 7, "name" : "7 міс" },
                    {"id": 8, "name" : "8 міс" },
                    {"id": 9, "name" : "9 міс" },
                    {"id": 10, "name" : "10 міс" },
                    {"id": 11, "name" : "11 міс" },
                    {"id": 12, "name" : "12 міс" },
                ],
                //city: null,
                country: null,
                startDate: moment(new Date(new Date().getFullYear(), new Date().getMonth(), (new Date().getDate() + 1))).format('DD.MM.YYYY'),
                userTypeList: [],
                userType: {},
                endDate: null,
                ticketNumber: null,
                ticketPrice: null,
                submitCheck: false,
                periodOptionStatus: false,
                optionsStartDate: {
                    format: 'DD.MM.YYYY',
                    useCurrent: false,
                    minDate: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
                    maxDate: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 90),
                    locale: 'uk',
                    icons: {
                        previous: 'icon arrow-point-to-left',
                        next: 'icon arrow-point-to-right',
                        time: "fa fa-clock-o",
                        date: "fa fa-calendar",
                    },
                },
                errors: {},
            };
        },

        validations: {
            startDate:{ required},
            periodOption:{ required},
            country:{required},
            //city:{required},
            periodOptions:{ required},
            carType:{ required},
            //ticketNumber:{required},
            //ticketPrice:{required, numeric}
        },

        created() {
            this.userTypeList = this.$store.getters.CUSTOMER_TYPES;
            this.setCustomerType();
            this.fetchParams();
        },

        mounted: function () {
        },

        computed: {
            countryStore() {
                if(this.dataCountry){
                    return this.dataCountry
                }
            },

            dateValidate(){
                let startYear = parseInt(moment(this.startDate,'DD/MM/YYYY').format('YYYY'));
                let startMonth = parseInt(moment(this.startDate,'DD/MM/YYYY').format('MM'));
                let startDay = parseInt(moment(this.startDate,'DD/MM/YYYY').format('DD'));
                let startHour = parseInt(moment(this.startDate,'DD/MM/YYYY/HH:m').format('HH'));
                let startMinutes = parseInt(moment(this.startDate,'DD/MM/YYYY/HH:m').format('mm'));
                let endYear = parseInt(moment(this.endDate,'DD/MM/YYYY').format('YYYY'));
                let endMonth = parseInt(moment(this.endDate,'DD/MM/YYYY').format('MM'));
                let endDay = parseInt(moment(this.endDate,'DD/MM/YYYY').format('DD'));
                let endHour = parseInt(moment(this.endDate,'DD/MM/YYYY/HH:m').format('HH'));
                let endMinutes = parseInt(moment(this.endDate,'DD/MM/YYYY/HH:m').format('mm'));
                let diffDays = moment(new Date((endYear+1), new Date(endMonth-1), new Date(endDay-1), new Date(endHour-1), new Date(endMinutes-1))).diff(new Date((startYear+1), new Date(startMonth-1), new Date(startDay-1), new Date(startHour-1), new Date(startMinutes-1)), 'hours', true);

                return diffDays < 0
            }
        },

        watch: {
        },

        methods: {
            submitHandler() {
                this.submitCheck = true;
                if (this.$v.$invalid) {
                    this.$v.$touch()
                    return
                }

                /*if(this.dateValidate){
                    return false
                }
                */

                let params = {
                    carType: this.carType,
                    userType: this.userType,
                    country: this.country,
                    startDate: (typeof this.startDate.getMonth === 'function')?this.startDate.toLocaleDateString():this.startDate,
                    periodOption: this.periodOption,
                }

                this.$store.commit('SET_GREENCARD_SEARCH_PARAMS', params);
                if (this.checkResultRoute()) {
                    this.$router.go();
                } else {
                    this.$router.push({name: `greenCardResults`});
                }
            },
            otherCountry: function () {
                let name = 'ТС зареєстровано в іншій країні';

                let results = this.otherVariants.filter((country) => {
                    return name.includes(country.name_ru);
                });

                if(results.length === 0) {
                    return {
                        name: null,
                    };
                }

                return results[0];
            },

            setCustomerType() {
                //TODO Установка флага "Фізична особа" по умолчанию
                this.userType = this.userTypeList.find(option => option.id === 1);
            },
            countrySelect(data) {
                this.country = data.country;
            },

            isInvalid(val) {
                return val == null && this.submitForm;
            },

            fetchParams(){
                    this.carType = this?.dataCarType ?? this.carType;
                    this.userType = this?.dataUserType ?? this.userTypeList.find(option => option.id === 1);
                    this.startDate = this?.dataStartDate ?? this.startDate;
                    this.periodOption = this?.dataPeriodOption ?? this.periodOption;
                    this.country = this?.dataCountry ?? this.country    ;
            },

            checkResultRoute() {
                return this.$router.currentRoute.name === 'greenCardResults';
            },
            checkCarType(){
                /*if(this.registrationType !== 3){
                    if(this.carType !== undefined){
                        if(this.carType.group === "CARGO" || this.carType.group === "TRAILER"){
                            this.euroDisabled = true;
                            this.euroChecked = false;
                            $('.params-city-status').removeClass('disabled');
                        }else{
                            this.euroDisabled = false;
                        }
                    }
                }*/
            },
            checkPeriodOption(){
                if(!this.periodOption || this.periodOption.id == undefined){
                    this.periodOptionStatus = false;
                }else{
                    this.periodOptionStatus = true;
                }
            },
        }
    }
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>


