<template>
    <div class="container kb-list-container kb-category-page">
        <div class="container kb-go-main">
            <router-link class="kb-go-back" :to="{ name: 'knowledgeBase' }">
                <svg width="19" height="12" viewBox="0 0 19 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M17 4.5H1M1 4.5L4.5 1M1 4.5L4.5 8" stroke="#009BEB"/>
                </svg>
                <span>Головна</span>
            </router-link>
            <div v-if="category" class="kb-breadcrump no-active">
                <div class="kb-breadcrump-name">/</div>
                <div class="kb-breadcrump-name">{{ category.name }}</div>
                <div class="kb-breadcrump-icon"><img :src="category.categoryImageUrl"/></div></div>
        </div>
        <div v-if="condition.isLoadArticles" class="row">
            <preview-loader v-for="index in 3" :key="index"></preview-loader>
        </div>

<!--        <div class="row kb-list-wrap" v-else-if="knowledgeBaseArticles && knowledgeBaseArticles.length > 0">-->
<!--            <preview v-for="article in knowledgeBaseArticles" :key="article.id"-->
<!--                     :preview="article.previewImageUrl"-->
<!--                     :isDisableForPartner="article.isDisableForPartner"-->
<!--                     :status-id="article.statusId"-->
<!--                     :slug="article.slug" :title="article.title" :description="article.description">-->
<!--                {{article}}-->
<!--            </preview>-->
<!--        </div>-->
        <div v-else-if="knowledgeBaseArticles && knowledgeBaseArticles.length > 0" class="knowledge-articles kb-block">
            <div class="knowledge-articles-cards">
                <div class="col-lg-4" v-for="article in knowledgeBaseArticles" :key="article.id">
                    <preview
                        :id="article.id"
                        :key="article.id"
                        :previewImage="article.previewImageUrl"
                        :isDisableForPartner="article.isDisableForPartner"
                        :status-id="article.statusId"
                        :title="article.title"
                        :tags-list="article.tags"
                    >
                        {{article}}
                    </preview>
                </div>
            </div>
        </div>




        <div v-else class="empty-result">
            <p>За вашим запитом нічого не знайдено</p>
        </div>
        <div style="text-align: center">
            <pagination v-show="total > 0" :total="total"
                        :page.sync="listQuery.page"
                        :limit.sync="listQuery.limit" @pagination="initList()" />
        </div>
    </div>
</template>

<script>

// import preview from './components/preview';
import { show as articleShow } from '@/api/article';
// import previewLoader from './components/previewLoader';
import Pagination from '@/components/Pagination';
import preview from './components/preview';
import { list as articleList, showCategory } from '@/api/knowledgeBase';
import { userViewedNews } from '@/api/news';
import previewLoader from './components/previewLoader';


export default {
    components: {
        previewLoader,
        preview,
        Pagination
    },
    data: function () {
        return {
            total: 10,
            condition: {
                isLoadArticles: true,
            },
            listQuery: {
                page: 1,
                limit: 10,
            },
            category: null,
            knowledgeBaseArticles: {},
        }
    },
    created: async function() {
        const params = {
            id: this.$route.params.id,
            ... this.listQuery
        }

        showCategory(params).then((response) => {
            this.category = response.data?.data?.category ?? [];
            this.knowledgeBaseArticles = response.data?.data?.items ?? [];
            this.total = response.data?.data?.total;
            this.category = response.data?.data?.category ?? null;
            this.condition.isLoadArticles = false;
        });
    },
    mounted () {
      window.scrollTo(0, 0)
    },
    methods: {
        initList: function () {}
    }
}
</script>

<style lang="scss" scoped>
    .author {
        font-weight: 700;
        font-size: 18px;
        line-height: 27px;
        color: #24282c;
    }
    .banner-preview {
        border-radius: 5px;
        width: 100%;
        height: 300px;
        -o-object-fit: cover;
        object-fit: cover;
        -o-object-position: right bottom;
        object-position: right bottom;
    }
    .date {
        font-size: 18px;
    }
</style>
