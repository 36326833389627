/**
 *
 * @returns {*|AxiosPromise}
 * @param orderId
 */
export function showOrder(orderId, typeId = 0) {
    return axios({
        url: `/api/orders/${orderId}/${typeId}`,
        method: 'get',
    });
}

/**
 *
 * @param data
 * @returns {*}
 */
export function saveOrder(data) {
    return axios({
        url: `/api/${process.env.VUE_APP_SERVER_MODE}/insurance/order/osago`,
        method: 'post',
        data: data,
    });
}

/**
 *
 * @param orderId
 * @returns {*}
 */
export function concludeOrder(orderId) {
    return axios({
        url: `/api/${process.env.VUE_APP_SERVER_MODE}/insurance/contract/confirm`,
        method: 'post',
        data: {
            orderId: orderId
        },
    });
}

/**
 *
 * @param params
 * @returns {*}
 */
export function list(params) {
    return axios({ url: '/api/orders/list',
                   method: 'post',
                   data: params
    });
}

/**
 *
 * @param inn
 * @param type
 * @returns {*}
 */
export function getInsuredsByInn(inn, type) {
    return axios({ url: '/api/orders/insuredsByInn',
        method: 'post',
        data: {
            inn: inn,
            typeId: type
        },
    });
}

/**
 *
 * @param contractId
 * @returns {*}
 */
export function downloadContract(contractId, typeId) {

    return axios({ url: '/api/' + process.env.VUE_APP_SERVER_MODE + '/insurance/contract/take',
        method: 'post',
        data: {
            contractId : contractId,
            orderType : typeId,
        },
    })
}

/**
 * @param userId
 * @param orderId
 * @param type
 * @returns {*}
 */
export function showContractFile(userId, orderId, type = 'osago') {
    return window.open(`/api/orders/show-contract/${userId}/${orderId}?type=${type}`);
}

/**
 *
 * @param userId
 * @param orderId
 * @returns {Window}
 */
export function downloadOrder(userId, orderId) {
    return window.open(`/api/orders/draft/${userId}/${orderId}`);
}

/**
 *
 * @param query
 * @returns {Promise<AxiosResponse<any>>}
 */
export function downloadExcel(query) {
    return axios.post('/api/orders/excel', query, {
                            responseType: 'blob'
                        });
}

/**
 *
 * @param params
 * @returns {*}
 */
export function prolongation(params) {
    return axios({ url: '/api/orders/prolongation',
        method: 'post',
        data: params
    });
}

/**
 *
 * @param data
 * @returns {*}
 */
export function changeProlongStatus(data) {
    return axios({
        url: `/api/orders/prolongation/status`,
        method: 'post',
        data: data,
    });
}

/**
 *
 * @returns {*}
 */
export function countNeedProlongation() {
    return axios({
        url: '/api/orders/prolongation-count',
        method: 'get'
    });
}

/**
 *
 * @param query
 * @returns {Promise<AxiosResponse<any>>}
 */
export function downloadPdf(link) {
    return axios.get(link, {
        // method: 'get',
        responseType: 'blob'
    });
}

/**
 *
 * @param orderId
 * @param amount
 * @returns {*}
 */
export function getPaymentInvoice(orderId, amount = null, invoiceType = null, payParts = 2, usageMonths = null) {

    return axios({ url: '/api/orders/get-invoice',
        method: 'post',
        data: {
            orderId: orderId,
            amount: amount,
            type: invoiceType,
            payParts: payParts,
            usageMonths: usageMonths
        }
    });
}

/**
 *
 * @param orderId
 * @param amount
 * @returns {*}
 */
export function monoPayPartsStatus(invoiceId) {
    return axios({ url: '/api/payments/mono-pay-parts-status',
        method: 'post',
        data: {invoiceId: invoiceId}
    }); // .then(response => response.data)
}

/**
 *
 * @param userId
 * @param orderId
 * @param params
 * @returns {Window}
 */
export function downloadInvoiceLegal(userId, orderId, params) {
    return window.open(`/api/orders/show-invoice-legal/${userId}/${orderId}?${params}`);
}
/*
export function getPaymentInvoiceForOrder(orderId, amount = null, invoiceType = null) {

    return axios({ url: '/api/orders/get-invoice',
        method: 'post',
        data: {
            orderId: orderId,
            amount: amount,
            type: invoiceType
        }
    });
}*/
export function sendOtp(orderId,type) {
    return axios({ url: `/api/orders/send-otp/${orderId}`,
        method: 'post',
        data: {type: type}
    }); // .then(response => response.data)
}
export function checkOtp(orderId,otp) {
    return axios({ url: `/api/orders/check-otp/${orderId}`,
        method: 'post',
        data: {otp: otp}
    }); // .then(response => response.data)
}

