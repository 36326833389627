const DisplaySetting = {
    install(Vue) {
        const mobileWidth = 992

        // Vue.checkIsMobile = function () {
        //     Vue.prototype.$isMobile = window.innerWidth < mobileWidth
        // }

        // this.$on("resize", function () {
        //     Vue.prototype.$isMobile = window.innerWidth < mobileWidth
        // });
        // Vue.checkIsMobile = function () {
        //     return window.innerWidth < mobileWidth
        // }
        Vue.mixin({
            data() {
                return {
                    mobileWidth: 992
                }
            },
            methods: {
                checkIsMobile() {
                    Vue.prototype.$isMobile = window.innerWidth < mobileWidth
                    // return window.innerWidth < mobileWidth
                },
            },
            created() {
                window.addEventListener("resize", this.checkIsMobile);
                this.checkIsMobile()
            }
        });
        Vue.prototype.$isMobile = window.innerWidth < mobileWidth
    }
}

export default DisplaySetting;
