//TODO ?????????
export function orderGreenCard(offerInfo, searchInfo, userInfo, carInfo, orderId, params, bonusSystemValue = 0) {
    return axios({
        url: `/api/test/insurance/greencard/order/create`,
        method: 'post',
        data: {
            params:params,
            orderId: orderId,
            companyId: offerInfo.offerId,
            price: offerInfo.price,
            bonusSystemValue: bonusSystemValue,
            countryId: searchInfo.country.id,
            startDate: searchInfo.startDate,
            carTypeExternalId: searchInfo.carType.external_id,
            periodOptionId: searchInfo.periodOption.id,
            userTypeId: searchInfo.userType.id,
            allowedDocuments: offerInfo.allowed_documents,
            info: {
                identificationCode: userInfo.identificationCode,
                withoutIdentificationCode: userInfo.withoutIdentificationCode,
                withoutDocuments: userInfo.withoutDocuments,
                erdpyCode: userInfo.erdpyCode,
                name: userInfo.name,
                name_ua: userInfo?.name_ua || null,
                surname: userInfo.surname,
                surname_ua: userInfo?.surname_ua || null,
                dateBirth: userInfo.dateBirth,
                phone: userInfo.phone,
                mail: userInfo.mail,
                city: userInfo.city,
                street: userInfo.street,
                house: userInfo.house,
                apartment: userInfo.apartment,
                documentation: userInfo.documentation,
                documentType: userInfo.documentType,
                passportSerial: userInfo.passportSerial,
                passportNumber: userInfo.passportNumber,
                passportCompany: userInfo.passportCompany,
                passportDate: userInfo.passportDate,
                internationalPassport: userInfo.internationalPassport,
                contact: userInfo.contact || null,
                isFop: userInfo.isFop || false,
                isDP: userInfo.isDP || false,
            },
            carInfo:carInfo,
            offerInfo:offerInfo
        },
    });
}
