export default {
    computed:{
        patterns() {
            return this.$store.getters.AUTO_NUMBER_VALIDATION_LIST;
        }
    },
    methods: {
         validateCarNumber(number, groups = []) {
            if (Object.keys(this.patterns).length === 0) {
                this.$store.dispatch('loadPatterns');
            }

            if (groups.length === 0) {
                groups = Object.keys(this.patterns);
            }
            if(this.patterns?.vehicle){
                    for (const group of groups) {
                    const groupPatterns = this.patterns[group];
                    for (const pattern of groupPatterns) {
                        if (pattern.test(number.toUpperCase())) {
                            return true;
                        }
                    }
                }
            }
            return false;
        }
    }
}
