<template>
  <div class="mainsearch">
    <form @submit.prevent="send" ref="accidentlawnerForm">
      <div class="form">
        <div class="row">
          <div class="col-lg-12">
            <label class="label-field-line">
              <span>Введіть дані клієнта:</span>
              <span class="required-icon"></span>
            </label>
          </div>
          <div class="col-lg-4">

            <div class="form-group">
              <label>
                <span>Ім'я</span>
                <span class="required-icon"></span>
              </label>
              <div class="search-group">
                <input type="text" :class="['form-control', {invalid: (!$v.name.required && submitCheck)}]" v-model="name" placeholder="Ім'я">
                <small class="error" v-if="!$v.name.required && submitCheck">Вкажіть Ім'я</small>
              </div>
            </div>
          </div>

          <div class="col-lg-4">
            <div class="form-group">
              <label>Місто</label>


              <multiselect
                  :class="{'form-control' : true, 'is-invalid' : isInvalid(this.city)}"
                  v-model="city"
                  :options="citiesOptions"
                  placeholder="Введіть назву міста"
                  @search-change="searchCities"
                  :internal-search="false"
                  :max-height="120"
                  :options-limit="300"
                  :allow-empty="false"
                  label="name_full_name_ua"></multiselect>
              <small class="error"
                     v-if="!$v.city.required && submitCheck">Виберіть місто</small>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="form-group">
              <label>
                <span>Телефон</span>
                <span class="required-icon"></span>
              </label>
              <div class="search-group">
<!--                <input type="text" class="form-control" v-model="phone" placeholder="Телефон">-->
                <input v-model.trim="phone" v-mask="'+38 (###) ###-##-##'" type="text" placeholder="+38 (0" :class="['form-control', {invalid: (!$v.phone.required && submitCheck)}]">
                <small class="error" v-if="!$v.phone.required && submitCheck">Вкажіть телефон</small>
                <small class="error" v-else-if="!$v.phone.minLength">Введіть номер в форматі 380xxxxxxxx</small>
              </div>
            </div>
          </div>
          <div class="col-12"><hr></div>
          <div class="col-lg-12">
            <label class="label-field-line">
              <span>Оберіть тип послуги:</span>
              <span class="required-icon"></span>
            </label>
          </div>
          <div class="col-lg-4">
            <div class="form-group">
              <label>Тип послуги</label>
              <multiselect
                  :class="{'form-control' : true, 'is-invalid' : isInvalid(this.serviceType)}"
                  v-model="serviceType"
                  :options="serviceTypes"
                  :max-height="120"
                  label="name"
                  :allow-empty="false"
                  placeholder="Тип послуги"
                  ></multiselect>
            </div>
          </div>
          <div class="col-lg-12 insurance-nav d-flex justify-content-center">
            <button class="btn-blue-w" type="submit">
              Залишити заявку
            </button>
          </div>
        </div>
      </div>
    </form>
    <modal-lawyer-success
        ref="modalLawyerSuccess"
        @backToMain="backToMain"
    >

    </modal-lawyer-success>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import citySearch from '@/components/app/forms/CitySearch.vue';
import ModalLawyerSuccess from '../components/modal-lawyer-success';
import { insuranceMixin } from '@/mixin';
import {minLength, required} from "vuelidate/lib/validators";
import { sendAccidendLawyerForm as sendForm } from "@/api/lawyerAccident";
//import {sendAccidendLawyerForm} from "../../../api/lawyerAccident";


export default {
  components: {Multiselect, citySearch, ModalLawyerSuccess},
  mixins: [insuranceMixin],
  props: [],

  data: function () {
    return {
      name: null,
      phone: null,
      serviceType: null,
      serviceTypes: [
        {"id": 1,"name": "Виплати “під ключ”"},
        {"id": 2,"name": "Незалежна оцінка пошкоджень"},
      ],
      city: null,
      cities: null,
      citiesOptions: [ {"id": 1,"name": "Київ","name_full_name_ua": "м. Київ, Україна", "zone":1},
        {"id": 9,"name": "Дніпро","name_full_name_ua": "м. Дніпро, Дніпропетровська  обл., Україна", "zone":2},
        {"id": 11,"name": "Львів","name_full_name_ua": "м. Львів, Львівська  обл., Україна", "zone":1},
        {"id": 13,"name": "Одеса","name_full_name_ua": "м. Одеса, Одеська  обл., Україна", "zone":2},
        {"id": 14,"name": "Харків","name_full_name_ua": "м. Харків, Харківська  обл., Україна", "zone":2},
        {"id": 16,"name": "Запоріжжя","name_full_name_ua": "м. Запоріжжя, Запорізька  обл., Україна", "zone":3},
        {"id": 17,"name": "Кривий Ріг","name_full_name_ua": "м. Кривий Ріг, Дніпропетровська  обл., Україна", "zone":3},
        {"id": 21,"name": "Вінниця","name_full_name_ua": "м. Вінниця, Вінницька  обл., Україна", "zone":4},
        {"id": 36,"name": "Маріуполь","name_full_name_ua": "м. Маріуполь, Донецька  обл., Україна", "zone":4},
        {"id": 41,"name": "Полтава","name_full_name_ua": "м. Полтава, Полтавська  обл., Україна", "zone":4}],
      isLoading: false,
      submitCheck: false,
      userTypeList: [],
    };
  },

  created() {

  },

  mounted: function () {

  },

  computed: {
    otherCity: function () {
      let name = 'Інші населені пункти України';

      let results = this.cities.filter((city) => {
        return name.includes(city.name_full_name_ua);
      });

      if(results.length === 0) {
        return {
          id: 29751,
          name_full_name_ua: name,
          zone: 5,
        };
      }

      return results[0];
    },
  },

  validations: {
    name:{required},
    phone:{required,minLength: minLength(19)},
    city:{required},
    serviceType: {required},
  },

  watch: {

  },

  methods: {

    fetchCities(query) {
      axios.get('/api/directories/cities/find?city=' + query).then(({data}) => {
        this.cities = data.data;
        this.citiesOptions = data.data;

        if (this.citiesOptions.length === 0) {
          this.citiesOptions.push(this.otherCity);
        }
      }).catch(err => (console.log(err)));
    },
    searchCities(query) {
      if(query.length > 1){
        this.fetchCities(query);
      }
    },

    citySelect(data) {
      this.city = data.city;
    },

    send() {

      this.submitCheck = true;

      if (this.$v.$invalid) {
        this.$v.$touch()
        window.scrollTo({ top: 300, behavior: 'smooth' });
        return
      }

      let params = {
        name: this.name,
        phone: this.phone,
        cityName: this.city.name_full_name_ua,
        serviceType: this.serviceType.name
      };

      sendForm(params).then((response) => {

        if (response && response.status) {
          this.submitCheck = false;
          this.$refs.modalLawyerSuccess.open();

          this.name = null;
          this.phone = null;
          this.city = null;
          this.serviceType = null;
          // this.$router.push({name: 'prime'});
          return true;
        }
        this.$alert('Щось пішло не так. Перевірте вхідні дані.')

      }).catch(error => {
        console.log(error)
        this.$swal('', 'Щось пішло не так. Перевірте вхідні дані.', 'error');
      });
    },

    backToMain(val) {
      this.$router.push({name: 'prime'});
    },
    isInvalid(val) {
      return val == null && this.submitCheck;
    },
    onlyLatinCharacters(str) {
      if(str == null || str == "") return true;
      return /^[a-zA-Z ]+$/.test(str);
    },

  }
}
</script>
<style scoped lang="scss">
  .label-field-line{
    font-size: 20px;
    color: #24282c;
    font-weight: bold;
    text-align: left;
    margin-bottom: 10px;
  }
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>


