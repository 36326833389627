<template>
  <div class="modal modal-container modal-compinate-plate modal-flight-success fade" id="modalCompinatePlate" tabindex="-1" role="dialog" aria-labelledby="modalFlightSuccess" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-body p-0">
<!--          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="40" height="40" fill="white"/>
              <path d="M28 12L12 28" stroke="black" stroke-width="2"/>
              <path d="M12 12L28 28" stroke="black" stroke-width="2"/>
            </svg>
          </button>-->

          <div class="container p-0">
            <div class="d-sm-none d-md-none d-lg-block d-xl-block d-none mt-3">
              <div class="text-center head-text">{{ (!!params) ? params.dataTranslate.attention : "" }}!</div>
              <div class="text-left sub-head-text m-3 mb-4" v-if="diiaCar.licensePlate">{{ (!!params) ? params.dataTranslate.titileLicensePlate : "" }}</div>
              <div class="text-left sub-head-text m-3 mb-4" v-else>{{ (!!params) ? params.dataTranslate.titleCarType : "" }}</div>
              <div class="row">
                <div class="col-12 d-flex flex-wrap align-items-center justify-content-end text-center">
                  <div class="row" >
                    <div class="col left-block p-5">
                      <div class="text-left block-text-head">{{ (!!params) ? params.dataTranslate.stepData : "" }}:</div>
                      <hr class="d-lg-block">
                      <div class="row ">
                        <div class="col-12 p-0">
                          <div class="row p-0">
                            <div class="col-12 text-left p-0">
                              <table class="table table-borderless">
                                <tbody>
                                <tr v-if="order && ['osago'].includes(params.tariffType)">
                                  <td class="table-label">{{ (!!params) ? params.dataTranslate.licensePlate : "" }}:</td>
                                  <td class="text-right">{{ diiaCar.licensePlate.toUpperCase() }}</td>
                                </tr>
                                <tr v-if="order && ['osago'].includes(params.tariffType)">
                                  <td class="table-label">{{ (!!params) ? params.dataTranslate.carType : "" }}:</td>
                                  <td class="text-right">{{ diiaCar.kindBody }}</td>
                                </tr>
                                <tr v-if="order && ['osago'].includes(params.tariffType) && diiaCar.cap_class != 'B5'">
                                  <td class="table-label">{{ (!!params) ? params.dataTranslate.carCapacity : "" }}:</td>
                                  <td class="text-right">{{ diiaCar.capacity }}</td>
                                </tr>
                                <tr v-if="order && ['osago'].includes(params.tariffType)">
                                  <td class="table-label">{{ (!!params) ? params.dataTranslate.ragistrationPlace : "" }}:</td>
                                  <td class="text-right">{{ diiaCar.address }}</td>
                                </tr>
                                <tr v-if="order && ['osago'].includes(params.tariffType)">
                                  <td class="table-label">{{ (!!params) ? params.dataTranslate.year : "" }}:</td>
                                  <td class="text-right">{{ diiaCar.makeYear }}</td>
                                </tr>
                                <tr v-if="order && ['osago'].includes(params.tariffType)">
                                  <td class="table-label">{{ (!!params) ? params.dataTranslate.birthdayAt : "" }}:</td>
                                  <td class="text-right">{{ stepCarInfo.birthdayAt }}</td>
                                </tr>
                                <tr v-if="order && ['osago'].includes(params.tariffType)">
                                  <td class="table-label">{{ (!!params) ? params.dataTranslate.vin : "" }}:</td>
                                  <td class="text-right">{{ diiaCar.vin }}</td>
                                </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                      <button class="btn btn-orange"  v-on:click="calculate">{{ (!!params) ? params.dataTranslate.updatePrice : "" }}</button>
                    </div>
                    <div class="col text-or-block d-flex flex-wrap align-items-center justify-content-end text-center">
                      <div class="line line-top"></div>
                      <p class="text-or">або</p>
                      <div class="line line-bottom"></div>
                    </div>
                    <div class="col right-block p-5">
                      <div class="text-left block-text-head">{{ (!!params) ? params.dataTranslate.calcData : "" }}:</div>
                      <hr class="d-lg-block">
                      <div class="row ">
                        <div class="col-12 p-0">
                          <div class="row p-0">
                            <div class="col-12 text-left p-0">
                              <table class="table table-borderless">
                                <tbody>
                                <tr v-if="order && ['osago'].includes(params.tariffType)">
                                  <td class="table-label">{{ (!!params) ? params.dataTranslate.licensePlate : "" }}:</td>
                                  <td class="text-right">{{ (calcCar.licensePlate) ? calcCar.licensePlate.toUpperCase() : '—' }}</td>
                                </tr>
                                <tr v-if="order && ['osago'].includes(params.tariffType)">
                                  <td class="table-label">{{ (!!params) ? params.dataTranslate.carType : "" }}:</td>
                                  <td class="text-right">{{ calcCar.kindBody || '—'  }}</td>
                                </tr>
                                <tr v-if="order && ['osago'].includes(params.tariffType) && params.calc_car_type != 'B5'">
                                  <td class="table-label">{{ (!!params) ? params.dataTranslate.carCapacity : "" }}:</td>
                                  <td class="text-right">{{ calcCar.capacity || '—'  }}</td>
                                </tr>
                                <tr v-if="order && ['osago'].includes(params.tariffType)">
                                  <td class="table-label">{{ (!!params) ? params.dataTranslate.ragistrationPlace : "" }}:</td>
                                  <td class="text-right">{{ calcCar.address || '—'  }}</td>
                                </tr>
                                <tr v-if="order && ['osago'].includes(params.tariffType)">
                                  <td class="table-label">{{ (!!params) ? params.dataTranslate.year : "" }}:</td>
                                  <td class="text-right">{{ calcCar.makeYear || '—'  }}</td>
                                </tr>
                                <tr v-if="order && ['osago'].includes(params.tariffType)">
                                  <td class="table-label">{{ (!!params) ? params.dataTranslate.birthdayAt : "" }}:</td>
                                  <td class="text-right">{{ calc_car_info.birthdayAt }}</td>
                                </tr>
                                <tr v-if="order && ['osago'].includes(params.tariffType)">
                                  <td class="table-label">{{ (!!params) ? params.dataTranslate.vin : "" }}:</td>
                                  <td class="text-right">{{ calcCar.vin || '—'  }}</td>
                                </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                      <button class="btn btn-outline-blue" v-on:click="leavePlate" data-dismiss="modal" aria-label="Close">{{ (!!params) ? params.dataTranslate.leave : "" }}</button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-5"></div>
            </div>
            <div class="mob d-sm-block d-md-block d-lg-none d-xl-none mt-3">
              <div class="row m-0" >
                <div class="col-12 p-0">
                  <div class="text-center head-text">{{ (!!params) ? params.dataTranslate.attention : "" }}!</div>
                  <div class="text-left sub-head-text m-3 mb-4" v-if="diiaCar.licensePlate">{{ (!!params) ? params.dataTranslate.titileLicensePlate : "" }}</div>
                  <div class="text-left sub-head-text m-3 mb-4" v-else>{{ (!!params) ? params.dataTranslate.titleCarType : "" }}</div>
                </div>
              </div>
              <div class="row p-0" >
                <div class="col-12 p-0">
                  <div class="combinate-plate-slider__wrapp">
                    <div class="combinate-plate-slider swiper-container ">
                      <div class="combinate-plate-slider__list swiper-wrapper mb-4 ">
                        <div class="combinate-plate-slider__item swiper-slide left-block"
                             data-toggle="modal" data-target="#modalCombinatePlate">
                          <div class="row m-0 mt-3">
                            <div class="col-12">
                              <div class="text-left block-text-head ml-3">{{ (!!params) ? params.dataTranslate.stepData : "" }}:</div>
                              <hr class="d-lg-block">
                              <table class="table table-borderless">
                                <tbody>
                                <tr v-if="order && ['osago'].includes(params.tariffType)">
                                  <td class="table-label">{{ (!!params) ? params.dataTranslate.licensePlate : "" }}:</td>
                                  <td class="text-right">{{ diiaCar.licensePlate.toUpperCase() }}</td>
                                </tr>
                                <tr v-if="order && ['osago'].includes(params.tariffType)">
                                  <td class="table-label">{{ (!!params) ? params.dataTranslate.carType : "" }}:</td>
                                  <td class="text-right">{{ diiaCar.kindBody }}</td>
                                </tr>
                                <tr v-if="order && ['osago'].includes(params.tariffType) && diiaCar.cap_class != 'B5'">
                                  <td class="table-label">{{ (!!params) ? params.dataTranslate.carCapacity : "" }}:</td>
                                  <td class="text-right">{{ diiaCar.capacity }}</td>
                                </tr>
                                <tr v-if="order && ['osago'].includes(params.tariffType)">
                                  <td class="table-label">{{ (!!params) ? params.dataTranslate.ragistrationPlace : "" }}:</td>
                                  <td class="text-right">{{ diiaCar.address }}</td>
                                </tr>
                                <tr v-if="order && ['osago'].includes(params.tariffType)">
                                  <td class="table-label">{{ (!!params) ? params.dataTranslate.year : "" }}:</td>
                                  <td class="text-right">{{ diiaCar.makeYear }}</td>
                                </tr>
                                <tr v-if="order && ['osago'].includes(params.tariffType)">
                                  <td class="table-label">{{ (!!params) ? params.dataTranslate.birthdayAt : "" }}:</td>
                                  <td class="text-right">{{ stepCarInfo.birthdayAt }}</td>
                                </tr>
                                <tr v-if="order && ['osago'].includes(params.tariffType)">
                                  <td class="table-label">{{ (!!params) ? params.dataTranslate.vin : "" }}:</td>
                                  <td class="text-right">{{ diiaCar.vin }}</td>
                                </tr>
                                </tbody>
                              </table>
                              <hr class="d-lg-block">
                              <button type="button" class="btn btn-orange"  v-on:click="calculate">{{ (!!params) ? params.dataTranslate.updatePrice : "" }}</button>
                              <div class="bottom-hr"></div>
                            </div>
                          </div>
                        </div>
                        <div class="combinate-plate-slider__item swiper-slide left-block"
                             data-toggle="modal" data-target="#modalCombinatePlate">
                          <div class="row m-0 mt-3">
                            <div class="col-12">
                              <div class="text-left block-text-head ml-3">{{ (!!params) ? params.dataTranslate.calcData : "" }}:</div>
                              <hr class="d-lg-block">
                              <table class="table table-borderless">
                                <tbody>
                                <tr v-if="order && ['osago'].includes(params.tariffType)">
                                  <td class="table-label">{{ (!!params) ? params.dataTranslate.licensePlate : "" }}:</td>
                                  <td class="text-right">{{ (calcCar.licensePlate) ? calcCar.licensePlate.toUpperCase() : '—' }}</td>
                                </tr>
                                <tr v-if="order && ['osago'].includes(params.tariffType)">
                                  <td class="table-label">{{ (!!params) ? params.dataTranslate.carType : "" }}:</td>
                                  <td class="text-right">{{ calcCar.kindBody || '—'  }}</td>
                                </tr>
                                <tr v-if="order && ['osago'].includes(params.tariffType) && params.calc_car_type != 'B5'">
                                  <td class="table-label">{{ (!!params) ? params.dataTranslate.carCapacity : "" }}:</td>
                                  <td class="text-right">{{ calcCar.capacity || '—'  }}</td>
                                </tr>
                                <tr v-if="order && ['osago'].includes(params.tariffType)">
                                  <td class="table-label">{{ (!!params) ? params.dataTranslate.ragistrationPlace : "" }}:</td>
                                  <td class="text-right">{{ calcCar.address || '—'  }}</td>
                                </tr>
                                <tr v-if="order && ['osago'].includes(params.tariffType)">
                                  <td class="table-label">{{ (!!params) ? params.dataTranslate.year : "" }}:</td>
                                  <td class="text-right">{{ calcCar.makeYear || '—'  }}</td>
                                </tr>
                                <tr v-if="order && ['osago'].includes(params.tariffType)">
                                  <td class="table-label">{{ (!!params) ? params.dataTranslate.birthdayAt : "" }}:</td>
                                  <td class="text-right">{{ calc_car_info.birthdayAt }}</td>
                                </tr>
                                <tr v-if="order && ['osago'].includes(params.tariffType)">
                                  <td class="table-label">{{ (!!params) ? params.dataTranslate.vin : "" }}:</td>
                                  <td class="text-right">{{ calcCar.vin || '—'  }}</td>
                                </tr>
                                </tbody>
                              </table>
                              <hr class="d-lg-block">
                              <button  type="button" class="btn btn-outline-blue" v-on:click="leavePlate" data-dismiss="modal" aria-label="Close">{{ (!!params) ? params.dataTranslate.leave : "" }}</button>
                              <div class="bottom-hr"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="combinate-plate__pagination swiper-pagination"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <custom-alert :message="alertMessage" popupId="compinate" @close="onAlertClose" />
  </div>
</template>

<script>

import axios from "axios";
import Swiper from 'swiper/swiper-bundle.min';
import CustomAlert from '@/components/app/modals/CustomAlert.vue';
import {getByParams as getInsuredByParams} from "@/api/insured";
export default {
  components:{
    CustomAlert, Swiper
  },
  props: {
  },
  data: function () {
    return {
      alertMessage: '',
      calcCar: false,
      diiaCar: false,
      curPlateInfo: false,
      stepCarInfo: false,
      calc_car_info: false,
      diiaFlag: false,
      carType: false,
      plate: false,
      model: false,
      mark: false,
      vin: false,
      otkDate: false,
      endDate: false,
     // calculatedPeriod: false,
      endOrderDate: false,
      changedCity: false,
      year: false,
      city: false,
      privilegeType: false,
      carTypeOption: false,
      step_city_data: false,
      params:null,
      query_params:null,
      carTypes:[],
      width:0,
      langPrefix:"",
      order:null,
      periodOption: {"id": 12, "name" : "1 рік" },
      periodOptions: [
        {"id": 15, "name" : "15 д" },
        {"id": 1, "name" : "1 міс" },
        {"id": 2, "name" : "2 міс" },
        {"id": 3, "name" : "3 міс" },
        {"id": 4, "name" : "4 міс" },
        {"id": 5, "name" : "5 міс" },
        {"id": 6, "name" : "6 міс" },
        {"id": 7, "name" : "7 міс" },
        {"id": 8, "name" : "8 міс" },
        {"id": 9, "name" : "9 міс" },
        {"id": 10, "name" : "10 міс" },
        {"id": 11, "name" : "11 міс" },
        {"id": 12, "name" : "1 рік" },
      ],
    }
  },
  created() {
    this.$root.$on('setCompinatePlateParameters', ({ calcCar, diiaCar, order, privilegeType, diiaFlag, params, curPlateInfo, calc_car_info, stepCarInfo }) => {
      this.calcCar = calcCar;
      this.diiaCar = diiaCar;
      this.curPlateInfo = curPlateInfo;
      this.calc_car_info = calc_car_info;
      this.stepCarInfo = stepCarInfo;
      this.order = order;
      this.privilegeType = privilegeType;
      this.diiaFlag = diiaFlag;
      this.params = params;
      this.step_city_data = params.stepCityData;
      this.changedCity = params.changedCity;
      let storDateInfo = this.$store.state.osagoStore.dateInfo;
      this.periodOption = this.periodOptions.find(option => option.id === storDateInfo.period_id);
    });
  },
  computed: {
    calculatedPeriod(){
      return this.periodOption.name;
    }
  },
  beforeDestroy() {
    this.$root.$off('setCompinatePlateParameters');
  },
  mounted() {
    const mySwiper = new Swiper('.swiper-container', {
      // Настройки Swiper.js здесь
      slidesPerView: 1,
      spaceBetween: 10,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    });
    if(this.lang == 'ru'){
      this.langPrefix = '/ru'
    }
    //this.fetchCarTypes();
  },
  methods: {
    onAlertClose() {
      this.logicAfterAlertShouldRun();
    },
    showAnimateModal() {
      this.$root.$emit('showAnimateModalPreloader', {
        dataTitle : "Триває процес розрахунку поліса для авто",
        dataSubTitle: "Це займає від 5 до 10 секунд"
      });
    },
    hideAnimateModal() {
      this.$root.$emit('hideAnimateModalPreloader');
    },
    leavePlate(){
      this.plate = this.calcCar.licensePlate;
      this.vin = this.calcCar.vin;
      this.year = this.calcCar.year;
      this.city = this.params.calcDataCity;

      let storRequestData = this.$store.state.osagoStore.requestData;
      let storDateInfo = this.$store.state.osagoStore.dateInfo;
      let storInfoOrder = this.$store.state.osagoStore.infoOrder;

      let params = this.getParams(storRequestData, storInfoOrder, storDateInfo, true);
      let paramsCar = this.getParamsCar();

      this.setVuexParams({'params':params,'paramsCar':paramsCar});

      this.$root.$emit('completeCalcAfterChangePlate', {
        plate: this.calcCar.licensePlate,
        item:false,
        status:"success",
        changedCity:this.changedCity
      });

    },
    fetchCarTypes() {
      axios.get('/api/types/list?lang=' + this.lang)
          .then(({data}) => {
            this.carTypes = data.data;
          });
    },
    loadCarInfo(plate) {
       this.showAnimateModal();
        axios({url: '/api/directories/car/' + plate.trim(), method: 'GET'}).then(({data}) => {

            if(data.data == null) {
              return;
            }
            this.width = 15;

            if(['osago'].includes(this.params.tariffType)){
              this.plate = plate;
              this.mark = data.data.mark;
              this.model = data.data.model;
              this.vin = data.data.vin;
              this.year = data.data.year;
              this.city = (this.step_city_data)? this.step_city_data : data.data.city;
              this.carType = this.carTypes.find((item) => {
                return item.external_id[0] == data.data.cap_class[0];
              });


              this.carTypeOptions = this.getCarTypeOptions(this.carType);
              this.carTypeOption = this.carTypeOptions.find((item, index, list) => {
                return data.data.cap_class == item.value;
              });
            }
            if(this.params.tariffType == 'osago'){
              this.sendOsago();
            }

          }).catch(() => {});
    },
    getCarTypeOptions(carType) {

      if (carType === null || carType === undefined) {
        return [];
      }

      if (carType.types === undefined && carType.engines === undefined) {
        return [];
      }

      if (carType.types.length === 0) {
        return this.carType.engines;
      }

      if (carType.engines.length === 0) {
        return this.carType.types;
      }
    },
    getContractPeriod(){
      let storDateInfo = this.$store.state.osagoStore.dateInfo;
      if(!this.otkDate && !this.endDate){
        this.endOrderDate  = moment(storDateInfo.startDate, 'DD/MM/YYYY').subtract(1, 'days').add(1, 'years');
        //this.calculatedPeriod = '1 рік';
        this.periodOption = this.periodOptions.find(option => option.id === 12);
      }else{
        this.getPeriodNew(storDateInfo);
      }
    },
    getPeriodNew(storDateInfo){
      let currentValue = this.endDate ? this.endDate : this.otkDate;
      let currentMoment = moment.unix(currentValue); // Установить начало дня
      let startMoment = moment(storDateInfo.startDate, 'DD/MM/YYYY').startOf('day'); // Установить начало дня

      startMoment.subtract(1, 'days'); //фикс даты начала

      let diffYears = currentMoment.diff(startMoment, 'years', true);
      let diffMonths = currentMoment.diff(startMoment, 'months', true);
      let diffDays = currentMoment.add(1, 'days').diff(startMoment, 'days', true) ;

      if (diffYears >= 1) {
        let count = Math.floor(diffYears); // Округлить до ближайшего меньшего года
        this.periodOption = this.periodOptions.find(option => option.id === 12);
        this.endOrderDate = startMoment.add(1, 'years').toDate();
      } else if (diffMonths >= 1) {
        let count = Math.floor(diffMonths); // Округлить до ближайшего меньшего месяца
        this.periodOption = this.periodOptions.find(option => option.id === count);
        this.endOrderDate = startMoment.add(count, 'months').toDate();
      } else {
        let count = Math.floor(diffDays); // Округлить до ближайшего меньшего дня
        if (count >= 15) {
          //this.calculatedPeriod = '15 днів';
          this.endOrderDate = startMoment.add(15, 'days').toDate();
          this.periodOption = this.periodOptions.find(option => option.id === 15);
        } else {
          this.periodOption = this.periodOptions.find(option => option.id === 12);
          //this.calculatedPeriod = count + ' рік';
          /*setTimeout(() => {
            this.otkDate = null;
            this.endDate = null;
          }, 100);*/
        }
      }
    },
    getParams(storRequestData, storInfoOrder, storDateInfo, leave = false){

      let otkDate, withoutOtk, endDate;
      if(leave){
        otkDate = storRequestData.otkDate;
        endDate = storRequestData.endDate;
        withoutOtk = storRequestData.withoutOtk;
      }else if(['CARGO','TRAILER','BUS'].includes(this.diiaCar.carType.group)){
        otkDate = this.otkDate || moment(storDateInfo.startDate, "DD.MM.YYYY").add(1, 'years').subtract(1, 'days').utc(true).unix();
        endDate = (storRequestData.endDate)? storRequestData.endDate : null;
        withoutOtk = storRequestData.withoutOtk ? 1 : 0;
      }else{
        otkDate = null;
        endDate = (storRequestData.endDate)? storRequestData.endDate : null;
        withoutOtk = storRequestData.withoutOtk ? 1 : 0;
      }

      let params = {
        months: storRequestData.months,
        isTaxi: storRequestData.isTaxi ? 1 : 0,
        isEuroCar: storRequestData.isEuroCar ? 1 : 0,
        experience: storRequestData.experience,
        changedCarTypeFlag : storRequestData.changedCarTypeFlag,
        changedCarTypeOptionFlag : storRequestData.changedCarTypeOptionFlag,
        changedCityFlag : storRequestData.changedCityFlag,
        franchise: storInfoOrder.offer.franchise !== null && storInfoOrder.offer.franchise !== undefined
            ? parseInt(storInfoOrder.offer.franchise)
            : 'all',
        otkDate: otkDate,
        withoutOtk:withoutOtk,
        startDate: storDateInfo.startDate,
        customerPrivilege: storRequestData.customerPrivilege ?? null,
        registrationType: storRequestData.registrationType,
        customerType: storRequestData.customerType,
        //endDate:(this.endDate)? moment(this.endDate, "DD.MM.YYYY").utc(true).unix() : null,
        endDate:endDate,
        autoCategoryType: (!leave) ? this.curPlateInfo.cap_class : storRequestData.autoCategoryType,
        registrationPlaceId: this.city.id,
        zone: this.city.zone,
        cityName: this.city.name_full_name_ua,
        carTypeInfo: (!leave) ? this.diiaCar.carType : storRequestData.carTypeInfo,
        cityInfo: this.city,
        period_id: this.periodOption.id,
        car: {
          vin: this.vin || null,
          year: this.year || null,
          brand: '',
          model: '',
          number: this.plate || null,
          birthdayAt: this.stepCarInfo.birthdayAt,
        }
      };

      return params;
    },
    getParamsCar(){
      let paramsCar = {
        vin: this.vin || null,
        year: this.year || null,
        brand: '',
        model: '',
        number: this.plate || null
      };

      return paramsCar;
    },
    getDateInfo(storDateInfo){
      let dateInfo = {
        startDate: storDateInfo.startDate,
        endDate: moment(this.endOrderDate, "DD.MM.YYYY").format('DD.MM.YYYY'),
        period: this.periodOption.name,
        period_id: this.periodOption.id
      };

      return dateInfo;
    },
    setVuexParams(data){
      if(!!data.params) {
        this.$store.commit('SET_SEARCH_PARAMS', data.params);
      }
      if(!!data.osagoOffers) {
        this.$store.commit('SET_OSAGO_OFFERS', null);
      }
      if(!!data.paramsCar) {
        this.$store.commit('SET_CAR_INFO', data.paramsCar);
      }
      if(!!data.dateInfo) {
        this.$store.commit('SET_DATE_INFO', data.dateInfo);
      }
    },
   async sendOsago() {

     let storRequestData = this.$store.state.osagoStore.requestData;
     let storDateInfo = this.$store.state.osagoStore.dateInfo;
     let storInfoOrder = this.$store.state.osagoStore.infoOrder;

      this.otkDate = (['CARGO','TRAILER','BUS'].includes(this.diiaCar.carType.group)) ? storRequestData.otkDate : null;
      this.endDate = storRequestData.endDate || null;
      this.getContractPeriod();

     let params = this.getParams(storRequestData, storInfoOrder, storDateInfo, false);
     let paramsCar = this.getParamsCar();
     let dateInfo =  this.getDateInfo(storDateInfo);

     this.setVuexParams({'params':params,'paramsCar':paramsCar,'dateInfo':dateInfo, 'osagoOffers':true});

     try {
         axios.get('api/' + process.env.VUE_APP_SERVER_MODE + '/insurance/calculator/osago', {
         params: params
       }).then(async ({data}) => {
           if (data.status == 'success') {
             this.width = 50;

             let item = this.findItem(data.data, this.order);

             if (item) {

               await this.sleep(2000); // 2 секунды пауза, ждем пока из очереди запишется в БД новый оффер

               $("#modalCompinatePlate").modal('hide');
               item.prolongOrderId = this?.orderData?.offer?.prolongOrderId;

               const newOffer = this.setAdditionalData(item, this.order)

               this.forwardTo(newOffer, params);// this.forwardTo(item,params)
             } else {
               //location.href = '/osago-list';
               this.alertMessage = 'На жаль тариф, який ви обирали раніше, не доступний, ви будете переадресовані на сторінку з вибором доступних тарифів';
               this.hideAnimateModal();

               params.franchise = 'all';
               this.query_params = params;
               $("#custom-alert-compinate").modal('show');
             }
           }
         });
     } catch (error) {
       let errors = error.response.data.errors;
     }
   },
    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    setAdditionalData(item, oldOffer) {
        let newOffer = JSON.parse(
            JSON.stringify(
                Object.assign(item)
            )
        );

        // нужно реализовать будет для дго добавление данных тоже
        //const dgoData = this.getDgoData(newOffer, this.order);
        const autolawyerData = this.getAutolawyerData(newOffer, this.order);

        if (autolawyerData) {
            //newOffer.autolawyerCompanyId = autolawyerData.companyId;
            //newOffer.autolawyerId = autolawyerData.id;
            //newOffer.autolawyerOfferId = autolawyerData.offerId;
            newOffer.autolawyerPrice = autolawyerData.price;
            newOffer.autolawyerProgram = autolawyerData.program;
            newOffer.autolawyerZone =  autolawyerData.zone;
            newOffer.autolawyerAutoCategoryType =  autolawyerData.auto_category_type;
        }

        if (newOffer.autolawyerPrice && !isNaN(parseFloat(newOffer.autolawyerPrice))) {
            if (newOffer?.discountPrice) {
                newOffer.discountPrice = parseFloat(newOffer.discountPrice) + parseFloat(newOffer.autolawyerPrice);
                //newOffer.discountPrice = newOffer.discountPrice.toFixed(2);
            } else {
                newOffer.price = parseFloat(newOffer.price) + parseFloat(newOffer.autolawyerPrice);
                //newOffer.price = newOffer.price.toFixed(2);
            }
        }
        return newOffer;
    },
    getAutolawyerData(newOffer, tariff) {
        if ((tariff.offer.autolawyerId || tariff.offer.autolawyerPrice) && newOffer.listAutolawyer.length) {
            const foundAutolawyer = newOffer.listAutolawyer.find(item => item.program === tariff.offer.autolawyerProgram);
            return foundAutolawyer ? foundAutolawyer : null;
        }
    },
    logicAfterAlertShouldRun(){
      $("#modalCompinatePlate").modal('hide');
      $("#custom-alert-compinate").modal('hide');
      this.$router.push({name: `osagoResults`, query: this.query_params});
      $(".modal-backdrop").css('display','none')
    },
    finishCalc(item){
      this.width = 100;
      this.hideAnimateModal();
      //$("#modalCompinatePlate").modal('hide');
      this.$root.$emit('completeCalcAfterChangePlate', {
        plate: this.diiaCar.licensePlate,
        item:item,
        changedCity:this.changedCity
      });
    },
    findItem(data,tariff){
      const foundItem = data.find(item => item.companyId === tariff.offer.companyId && item.programmName == tariff.offer.programmName);
      return foundItem ? foundItem : null;
    },
    forwardTo(item, requestData) {
      let url;
      if(this.params.tariffType == 'osago'){
        this.forwardTosago(item, requestData);
      }
      this.updateParams(url, item);
    },
    forwardTosago(item,requestData){
      item.prolongOrderId = this?.orderData?.offer?.prolongOrderId;
      this.$store.commit('SET_INFO_ORDER', { offer: item, requestData: requestData });
    },
    updateParams(url, item){
       /*axios.get(url+"&leaveOrderId=1").then(({data}) => {
          this.finishCalc(item);
      });*/
       this.finishCalc(item);
    },
    calculate(){
      this.loadCarInfo(this.diiaCar.licensePlate);
    }
  }
}
</script>
<style lang="scss">
.modal-compinate-plate{
   overflow: auto;
  .modal-content{
    padding: 20px;
    border-radius: 5px;
  }

  .block-text-head{
    font-size: 18px;
    font-weight: 700;
    text-align: left;
    width: 100%;
  }
  .head-text{
    font-size: 30px;
    font-weight: 700;
  }
  .sub-head-text{
    font-size: 18px;
    font-weight: 400;
  }

  .line {
    position: absolute;
    background: #DDE2E5;
    width: 1px;
    height: 44%;
    &.line-top {
      top: 0;
    }
    &.line-bottom {
      bottom: 0;
    }
  }
  table{
    tbody{
      tr{
        td:nth-child(2){
          color: #24282C;

        }
      }
    }
  }
  .text-right{
    font-size: 16px;
    font-weight: 700;
  }
  button{
    &.btn{
      margin-top: 0px;
      width: 150px!important;
      border-radius: 5px;
      display: block;
      margin: auto auto 0;
    }
  }
  .row {
    display: flex;
    width: 100%;
  }

  .col {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    text-align: center;
  }
  .left-block,.right-block{
      box-shadow: 0px 0px 25px 0px #113C8F4D;
          border-radius: 5px;
      padding: 35px 32px!important;
     /* z-index: 1; !* Увеличьте значение z-index, если необходимо *!
      box-shadow: 0px 0px 25px 0px #113C8F4D;
      border-radius: 5px;*/
  }

  .text-or-block{
    min-width: 50px;
    max-width: 50px;
    p.text-or{
      position: relative;
      left: -1px;
      top: -45% ;
    }
  }

  .modal-dialog{
    max-width: 1000px;
  }

  hr {
    width: 100%;
  }
  .btn-bottom {
    position: absolute;
    bottom: 10px;
    left: 0;
    right: 0;
    margin: auto;
    width: 90%!important;
  }
  .table-label {
    min-width: 153px;
    color: #999DA6;
  }
  .swiper-pagination-bullet-active{
    background: #FFAD3D;
  }

  .mob{
    .swiper-container{
      overflow: hidden;
    }
    .swiper-wrapper {
      >div{
        padding: 15px;
      }
    }
    .left-block,.right-block{
      box-shadow: none;
      border-radius: 5px;
    }
    .left-block::before {
      content: '';
      position: absolute;
      top: 25px;
      left: 25px;
      right: 25px;
      bottom: 25px;
      /* border: 1px solid #113C8F4D; */
      box-shadow: 0 0 25px 0 #113C8F4D;
      border-radius: 5px;
    }
    .swiper-container-android{
      .swiper-slide{
        width: 100%!important;
      }
    }
    .swiper-container-horizontal{
      >.swiper-pagination-bullets{
        bottom: -7px;
      }
    }
    .head-text{
      font-size: 24px;
      font-weight: 700;
    }
    .sub-head-text{
      font-size: 15px;
      font-weight: 400;
    }
    .row{
      width: auto;
    }
    .bottom-hr {
      margin-bottom: 35px;
    }

    @media (max-width: 570px) {
      .table-label {
        min-width: auto;
      }
    }
    @media (max-width: 520px) {
      .table-label{
        display: inherit;
      }
    }
    @media (max-width: 490px) {
      table{
        tbody{
          tr{
            td:nth-child(2){
              display: table;
            }
          }
        }
      }
    }
    @media (max-width: 435px) {
      .table-label{
        width: 100%;
      }
      .bottom-hr {
        margin-bottom: 35px;
      }
      tr{
        td{
          display: inline-table;
        }
      }
    }
    @media (max-width: 380px) {
      table{
        tbody{
          tr{
            td:nth-child(2){
              font-size: 4vw;
            }
          }
        }
      }
    }
  }
  @media (max-width: 1050px) {
    .modal-content{
      max-width: 90%;
      margin: 0 auto;
    }
  }
  @media (max-width: 767px) {
    .container{
      max-width:100%
    }
  }
}

</style>
