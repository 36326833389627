<template>
    <div class="block">
<!--        //TODO need refactoring-->
        <RowBlockBonusSystem :item="item" v-if="item.typeId === 3"></RowBlockBonusSystem>

        <RowBlockPaymentPenalty :item="item" v-else-if="isPaymentPenalty(item)"></RowBlockPaymentPenalty>

        <RowBlockBuyingInsurance v-else-if="showBlockBuyingInsurance(item)" :item="item" ></RowBlockBuyingInsurance>
        <RowBlockBuyingGreenCardInsurance v-else-if="showBlockBuyingGreenCardInsurance(item)" :item="item" ></RowBlockBuyingGreenCardInsurance>
        <RowBlockBuyingManualInsurance v-else-if="showBlockBuyingManualInsurance(item)" :item="item" ></RowBlockBuyingManualInsurance>
        <RowBlockBuyingTourismInsurance v-else-if="showBlockBuyingTourismInsurance(item)" :item="item" ></RowBlockBuyingTourismInsurance>
        <RowBlockInsuranceCommission v-else-if="showBlockInsuranceCommission(item)" :item="item" ></RowBlockInsuranceCommission>
        <RowBlockReferralInsuranceCommission v-else-if="showBlockReferralInsuranceCommission(item)" :item="item" ></RowBlockReferralInsuranceCommission>
        <RowBlockInvoiceForClient v-else-if="showBlockInvoiceForClient(item)" :item="item" ></RowBlockInvoiceForClient>
        <RowBlockPaymentInsuranceThroughInvoicing v-else-if="showBlockPaymentInsuranceThroughInvoicing(item)" :item="item" ></RowBlockPaymentInsuranceThroughInvoicing>
        <RowBlockPaymentInsuranceByLegalEntity v-else-if="showBlockPaymentInsuranceByLegalEntity(item)" :item="item" ></RowBlockPaymentInsuranceByLegalEntity>
        <RowBlockPaymentMonobankPayParts v-else-if="showBlockMonobankPayParts(item)" :item="item" ></RowBlockPaymentMonobankPayParts>
        <RowBlockInvoiceForClientByAdmin v-else-if="showBlockInvoiceForClientByAdmin(item)" :item="item" ></RowBlockInvoiceForClientByAdmin>
        <RowBlockWithdrawInvoiceForClientByAdmin v-else-if="showBlockWithdrawInvoiceForClientByAdmin(item)" :item="item" ></RowBlockWithdrawInvoiceForClientByAdmin>
        <RowBlockRefundFundsCanceledContract v-else-if="showBlockRefundFundsCanceledContract(item)" :item="item" ></RowBlockRefundFundsCanceledContract>
        <RowBlockWritingOffCommissionDgo v-else-if="showRowBlockWritingOffCommissionDgo(item)" :item="item" ></RowBlockWritingOffCommissionDgo>
        <RowBlockWritingOffCommissionAutolawyer v-else-if="showRowBlockWritingOffCommissionAutolawyer(item)" :item="item" ></RowBlockWritingOffCommissionAutolawyer>
        <RowBlockWritingOffCommissionOsago v-else-if="showRowBlockWritingOffCommissionOsago(item)" :item="item" ></RowBlockWritingOffCommissionOsago>
        <RowBlockWritingOffCommissionManual v-else-if="showRowBlockWritingOffCommissionManual(item)" :item="item" ></RowBlockWritingOffCommissionManual>
        <RowBlockWritingOffCommissionGreenCard v-else-if="showRowBlockWritingOffCommissionGreenCard(item)" :item="item" ></RowBlockWritingOffCommissionGreenCard>
        <RowBlockWritingOffCommissionTourism v-else-if="showRowBlockWritingOffCommissionTourism(item)" :item="item" ></RowBlockWritingOffCommissionTourism>
        <RowBlockWritingOffReferralCommission v-else-if="showRowBlockWritingOffReferralCommission(item)" :item="item" ></RowBlockWritingOffReferralCommission>
        <RowBlockWritingOffCommissionTestOsago v-else-if="showBlockWritingOffCommissionTestOsago(item)" :item="item" ></RowBlockWritingOffCommissionTestOsago>
        <RowBlockWritingOffCommissionTestDgo v-else-if="showBlockWritingOffCommissionTestDgo(item)" :item="item" ></RowBlockWritingOffCommissionTestDgo>
        <RowBlockRefundFundsTestContract v-else-if="showBlockRefundFundsTestContract(item)" :item="item" ></RowBlockRefundFundsTestContract>
        <RowBlockPurchaseInsuranceThroughPaymentSystem v-else-if="showPurchaseInsuranceThroughPaymentSystem(item)" :item="item" ></RowBlockPurchaseInsuranceThroughPaymentSystem>
        <RowBlockBuyingBusInsurance v-else-if="showBlockBuyingBusInsurance(item)" :item="item" ></RowBlockBuyingBusInsurance>
        <RowBlockRefundFundsThroughPaymentSystem v-else-if="showBlockRefundFundsThroughPaymentSystem(item)" :item="item" ></RowBlockRefundFundsThroughPaymentSystem>

        <RowBlockBuyingInsuranceAutolawyer v-if="showBlockBuyingInsuranceAutolawyer(item)" :item="item" ></RowBlockBuyingInsuranceAutolawyer>
        <RowBlockInsuranceCommissionAutolawyer v-if="showBlockInsuranceCommissionAutolawyer(item)" :item="item" ></RowBlockInsuranceCommissionAutolawyer>
        <RowBlockRefundFundsCanceledContractAutolawyer v-if="showBlockRefundFundsCanceledContractAutolawyer(item)" :item="item" ></RowBlockRefundFundsCanceledContractAutolawyer>

        <RowBlockRefundFundsTestContractDgo v-if="showBlockRefundFundsTestContractDgo(item)" :item="item" ></RowBlockRefundFundsTestContractDgo>
        <RowBlockInsuranceCommissionDgo v-if="showBlockInsuranceCommissionDgo(item)" :item="item" ></RowBlockInsuranceCommissionDgo>
        <RowBlockBuyingInsuranceDgo v-if="showBlockBuyingInsuranceDgo(item)" :item="item" ></RowBlockBuyingInsuranceDgo>
        <RowBlockRefundFundsCanceledContractDgo v-if="showBlockRefundFundsCanceledContractDgo(item)" :item="item" ></RowBlockRefundFundsCanceledContractDgo>

    </div>
</template>

<script>
    import RowBlockBuyingInsurance from './RowBlockBuyingInsurance.vue';
    import RowBlockInvoiceForClient from './RowBlockInvoiceForClient.vue';
    import RowBlockBuyingInsuranceDgo from './RowBlockBuyingInsuranceDgo.vue';
    import RowBlockBuyingInsuranceAutolawyer from './RowBlockBuyingInsuranceAutolawyer.vue';
    import RowBlockInsuranceCommission from './RowBlockInsuranceCommission.vue';
    import RowBlockInsuranceCommissionDgo from './RowBlockInsuranceCommissionDgo.vue';
    import RowBlockInsuranceCommissionAutolawyer from './RowBlockInsuranceCommissionAutolawyer';
    import RowBlockReferralInsuranceCommission from './RowBlockReferralInsuranceCommission.vue';
    import RowBlockInvoiceForClientByAdmin from './RowBlockInvoiceForClientByAdmin.vue';
    import RowBlockWritingOffCommissionDgo from './RowBlockWritingOffCommissionDgo.vue';
    import RowBlockWritingOffCommissionAutolawyer from './RowBlockWritingOffCommissionAutolawyer';
    import RowBlockRefundFundsTestContract from './RowBlockRefundFundsTestContract.vue';
    import RowBlockWritingOffCommissionOsago from './RowBlockWritingOffCommissionOsago.vue';
    import RowBlockWritingOffCommissionGreenCard from './RowBlockWritingOffCommissionGreenCard.vue';
    import RowBlockWritingOffCommissionTourism from './RowBlockWritingOffCommissionTourism.vue';
    import RowBlockWritingOffReferralCommission from './RowBlockWritingOffReferralCommission';
    import RowBlockRefundFundsTestContractDgo from './RowBlockRefundFundsTestContractDgo.vue';
    import RowBlockWritingOffCommissionTestDgo from './RowBlockWritingOffCommissionTestDgo.vue';
    import RowBlockRefundFundsCanceledContract from './RowBlockRefundFundsCanceledContract.vue';
    import RowBlockRefundFundsCanceledContractDgo from './RowBlockRefundFundsCanceledContractDgo.vue';
    import RowBlockRefundFundsCanceledContractAutolawyer from './RowBlockRefundFundsCanceledContractAutolawyer';
    import RowBlockWritingOffCommissionTestOsago from './RowBlockWritingOffCommissionTestOsago.vue';
    import RowBlockPaymentInsuranceThroughInvoicing from './RowBlockPaymentInsuranceThroughInvoicing.vue';
    import RowBlockWithdrawInvoiceForClientByAdmin from './RowBlockWithdrawInvoiceForClientByAdmin.vue';
    import RowBlockPurchaseInsuranceThroughPaymentSystem from './RowBlockPurchaseInsuranceThroughPaymentSystem.vue';
    import RowBlockBuyingBusInsurance from './RowBlockBuyingBusInsurance.vue';
    import RowBlockBuyingGreenCardInsurance from './RowBlockBuyingGreenCardInsurance.vue';
    import RowBlockBuyingTourismInsurance from './RowBlockBuyingTourismInsurance.vue';
    import RowBlockPaymentInsuranceByLegalEntity from "@/views/score/components/RowBlockPaymentInsuranceByLegalEntity.vue";
    import RowBlockPaymentMonobankPayParts from "@/views/score/components/RowBlockPaymentMonobankPayParts.vue";
    import RowBlockRefundFundsThroughPaymentSystem from "./RowBlockRefundFundsThroughPaymentSystem";
    import RowBlockBonusSystem from "@/views/score/components/RowBlockBonusSystem.vue";
    import RowBlockPaymentPenalty from "@/views/score/components/RowBlockPaymentPenalty.vue";
    import RowBlockBuyingManualInsurance from "@/views/score/components/RowBlockBuyingManualInsurance.vue";
    import RowBlockWritingOffCommissionManual from "@/views/score/components/RowBlockWritingOffCommissionManual.vue";

    export default {
        components: {
            RowBlockWritingOffCommissionManual,
            RowBlockBuyingManualInsurance,
            RowBlockPaymentPenalty,
            RowBlockBonusSystem,
            RowBlockBuyingInsurance,
            RowBlockInvoiceForClient,
            RowBlockBuyingInsuranceDgo,
            RowBlockBuyingInsuranceAutolawyer,
            RowBlockInsuranceCommission,
            RowBlockInsuranceCommissionDgo,
            RowBlockInsuranceCommissionAutolawyer,
            RowBlockReferralInsuranceCommission,
            RowBlockRefundFundsCanceledContractDgo,
            RowBlockRefundFundsCanceledContractAutolawyer,
            RowBlockInvoiceForClientByAdmin,
            RowBlockWritingOffCommissionDgo,
            RowBlockWritingOffCommissionAutolawyer,
            RowBlockRefundFundsTestContract,
            RowBlockWritingOffCommissionOsago,
            RowBlockWritingOffCommissionGreenCard,
            RowBlockWritingOffCommissionTourism,
            RowBlockWritingOffReferralCommission,
            RowBlockRefundFundsTestContractDgo,
            RowBlockWritingOffCommissionTestDgo,
            RowBlockRefundFundsCanceledContract,
            RowBlockWritingOffCommissionTestOsago,
            RowBlockWithdrawInvoiceForClientByAdmin,
            RowBlockPaymentInsuranceThroughInvoicing,
            RowBlockPurchaseInsuranceThroughPaymentSystem,
            RowBlockBuyingBusInsurance,
            RowBlockBuyingGreenCardInsurance,
            RowBlockBuyingTourismInsurance,
            RowBlockPaymentInsuranceByLegalEntity,
            RowBlockPaymentMonobankPayParts,
            RowBlockRefundFundsThroughPaymentSystem
        },
        props: {
            item: {
                type: Object,
                default: () => {
                    return {
                        transactionMainType: null,
                    };
                },
            },
        },
        computed: {
            accountCashHistoryInfo: function () {
                return this.$store.getters.ACCOUNT_CASH_HISTORY
            }
        },
        methods: {
            isPaymentPenalty: function (item) {
              return item.subtype === this.accountCashHistoryInfo.subtype['earnings_for_paying_a_fine'];
            },
            isInvoiceForClientByAdminType: function(item) {
                return item.subtype === this.accountCashHistoryInfo.subtype['create_invoice_for_client_by_admin']
            },
            isWithdrawInvoiceForClientByAdminType: function(item) {
                return item.subtype === this.accountCashHistoryInfo.subtype['create_withdraw_invoice_for_client_by_admin'];
            },
            isPaymentInsuranceThroughInvoicingType: function(item) {
                return item.subtype === this.accountCashHistoryInfo.subtype['payment_of_insurance_through_invoicing'];
            },
            isPaymentInsuranceByLegalEntityType: function(item) {
                return item.subtype === this.accountCashHistoryInfo.subtype['payment_of_insurance_by_legal_entity'];
            },
            isInvoiceForClientType: function(item) {
                return (item.subtype === this.accountCashHistoryInfo.subtype['create_invoice_for_client'] || item.subtype === this.accountCashHistoryInfo.subtype['create_invoice_for_partner_by_main_user'] || item.subtype === this.accountCashHistoryInfo.subtype['create_invoice_for_main_user_by_partner']);
            },
            isRefundFundsTestContract: function(item) {
                return item.subtype === this.accountCashHistoryInfo.subtype['refund_of_funds_for_test_contract'];
            },
            isRefundFundsTestContractDgo: function(item) {
                return item.subtype === this.accountCashHistoryInfo.subtype['refund_of_funds_for_test_contract'] &&
                       item.existDgo;
            },
            isWritingOffCommissionTestDgo: function(item) {
                return item.subtype === this.accountCashHistoryInfo.subtype['writing_off_commission_for_test_contract_dgo'];
            },
            isWritingOffCommissionTestOsago: function(item) {
                return item.subtype === this.accountCashHistoryInfo.subtype['writing_off_commission_for_test_contract_osago'];
            },


            isReplenishmentType: function (item) {
                return item.transactionMainType === this.accountCashHistoryInfo.mainTypes['replenishment'];
            },
            isAccrualType: function (item) {
                return item.transactionMainType === this.accountCashHistoryInfo.mainTypes['accrual'];
            },
            isWriteOffType: function (item) {
                return item.transactionMainType === this.accountCashHistoryInfo.mainTypes['writeOff'];
            },
            isBuyingInsuranceDgoType: function (item) {
                return item.typeId === this.accountCashHistoryInfo.types['transaction'] &&
                       item.existDgo;
            },
            isBuyingInsuranceAutolawyerType: function (item) {
                return item.typeId === this.accountCashHistoryInfo.types['transaction'] &&
                    item.existAutolawyer;
            },
            isBuyingInsuranceType: function(item) {
                return item.typeId === this.accountCashHistoryInfo.types['transaction'] &&
                    item.subtype === this.accountCashHistoryInfo.subtype['purchase_insurance'];
            },
            isBuyingBusInsuranceType: function(item) {
                return item.typeId === this.accountCashHistoryInfo.types['transaction'] &&
                    item.subtype === this.accountCashHistoryInfo.subtype['purchase_bus_insurance'];
            },
            isBuyingGreenCardInsuranceType: function(item) {
                return item.typeId === this.accountCashHistoryInfo.types['transaction'] &&
                    item.subtype === this.accountCashHistoryInfo.subtype['purchase_green_card_insurance'];
            },
            isBuyingManualInsuranceType: function(item) {
                return item.typeId === this.accountCashHistoryInfo.types['transaction'] &&
                    item.subtype === this.accountCashHistoryInfo.subtype['purchase_manual_insurance'];
            },
            isBuyingTourismInsuranceType: function(item) {
                return item.typeId === this.accountCashHistoryInfo.types['transaction'] &&
                    item.subtype === this.accountCashHistoryInfo.subtype['purchase_tourism_insurance'];
            },
            isInsuranceCommissionType: function(item) {
                return item.typeId === this.accountCashHistoryInfo.types['transaction_bonus'];
            },
            isInsuranceCommissionDgoType: function(item) {
                return item.typeId === this.accountCashHistoryInfo.types['transaction_bonus'] &&
                    item.existDgo;
            },
            isInsuranceCommissionAutolawyerType: function(item) {
                return item.typeId === this.accountCashHistoryInfo.types['transaction_bonus'] &&
                    item.existAutolawyer;
            },
            isReferralInsuranceCommissionType: function(item) {
                return item.typeId === this.accountCashHistoryInfo.types['transaction_referral_bonus'];
            },
            isRefundFundsCanceledContract: function(item) {
                return  item.subtype === this.accountCashHistoryInfo.subtype['refund_of_funds_for_canceled_contract'];
            },
            isRefundFundsCanceledContractDgo: function(item) {
                return  item.subtype === this.accountCashHistoryInfo.subtype['refund_of_funds_for_canceled_contract'] && item.existDgo;
            },
            isRefundFundsCanceledContractAutolawyer: function(item) {
                return  item.subtype === this.accountCashHistoryInfo.subtype['refund_of_funds_for_canceled_contract'] && item.existAutolawyer;
            },
            isRefundFundsThroughPaymentSystem: function(item) {
                return  item.subtype === this.accountCashHistoryInfo.subtype['refund_of_funds_through_payment_system'];
            },
            isWritingOffCommissionOsago: function(item) {
                return  item.subtype === this.accountCashHistoryInfo.subtype['writing_off_commission_for_terminated_contract_osago'];
            },
            isWritingOffCommissionManual: function(item) {
                return  item.subtype === this.accountCashHistoryInfo.subtype['writing_off_commission_for_terminated_contract_manual'];
            },
            isWritingOffCommissionGreenCard: function(item) {
                return  item.subtype === this.accountCashHistoryInfo.subtype['writing_off_commission_for_terminated_green_card_insurance'];
            },
            isWritingOffCommissionTourism: function(item) {
                return  item.subtype === this.accountCashHistoryInfo.subtype['writing_off_commission_for_terminated_tourism_insurance'];
            },
            isWritingOffCommissionDgo: function(item) {
                return  item.subtype === this.accountCashHistoryInfo.subtype['writing_off_commission_for_terminated_contract_dgo'];
            },
            isWritingOffCommissionAutolawyer: function(item) {
                return  item.subtype === this.accountCashHistoryInfo.subtype['writing_off_commission_for_terminated_contract_autolawyer'];
            },
            // Referral transactions
            isWritingOffReferralCommission: function(item) {
                return  item.subtype === this.accountCashHistoryInfo.subtype['writing_off_referral_commission_for_terminated_contract_osago']
                    || item.subtype === this.accountCashHistoryInfo.subtype['writing_off_referral_commission_for_terminated_contract_dgo']
                    || item.subtype === this.accountCashHistoryInfo.subtype['writing_off_referral_commission_for_terminated_contract_green_card_insurance']
                    || item.subtype === this.accountCashHistoryInfo.subtype['writing_off_referral_commission_for_terminated_contract_tourism_insurance']
            },
            isPurchaseInsuranceThroughPaymentSystem: function(item) {
                return  item.subtype === this.accountCashHistoryInfo.subtype['purchase_insurance_through_payment_system'];
            },
            showBlockBuyingInsuranceDgo: function (item) {
                return this.isWriteOffType(item) && this.isBuyingInsuranceDgoType(item);

            },
            showBlockBuyingInsuranceAutolawyer: function (item) {
                return this.isWriteOffType(item) && this.isBuyingInsuranceAutolawyerType(item);
            },
            showBlockBuyingInsurance: function (item) {
                return this.isWriteOffType(item) && this.isBuyingInsuranceType(item);
            },
            showBlockBuyingBusInsurance: function (item) {
                return this.isWriteOffType(item) && this.isBuyingBusInsuranceType(item);
            },
            showBlockBuyingGreenCardInsurance: function (item) {
                return this.isWriteOffType(item) && this.isBuyingGreenCardInsuranceType(item);
            },
            showBlockBuyingManualInsurance: function (item) {
                return this.isWriteOffType(item) && this.isBuyingManualInsuranceType(item);
            },
            showBlockBuyingTourismInsurance: function (item) {
                return this.isWriteOffType(item) && this.isBuyingTourismInsuranceType(item);
            },
            showPurchaseInsuranceThroughPaymentSystem: function (item) {
                return this.isAccrualType(item) && this.isPurchaseInsuranceThroughPaymentSystem(item);
            },
            showBlockInsuranceCommission: function (item) {
                return this.isAccrualType(item) && this.isInsuranceCommissionType(item);
            },
            showBlockInsuranceCommissionDgo: function (item) {
                return this.isAccrualType(item) && this.isInsuranceCommissionDgoType(item);
            },
            showBlockInsuranceCommissionAutolawyer: function (item) {
                return this.isAccrualType(item) && this.isInsuranceCommissionAutolawyerType(item);
            },
            // Referral for all products types
            showBlockReferralInsuranceCommission: function (item) {
                return this.isAccrualType(item) && this.isReferralInsuranceCommissionType(item);
            },
            showBlockInvoiceForClient: function (item) {
                return this.isReplenishmentType(item) && this.isInvoiceForClientType(item);
            },
            showBlockPaymentInsuranceThroughInvoicing: function (item) {
                return this.isReplenishmentType(item) && this.isPaymentInsuranceThroughInvoicingType(item);
            },
            showBlockInvoiceForClientByAdmin: function (item) {
                return this.isReplenishmentType(item) && this.isInvoiceForClientByAdminType(item);
            },
            showBlockWithdrawInvoiceForClientByAdmin: function(item) {
                return this.isReplenishmentType(item) && this.isWithdrawInvoiceForClientByAdminType(item);
            },
            showBlockRefundFundsCanceledContract: function (item) {
                return this.isAccrualType(item) && this.isRefundFundsCanceledContract(item);
            },
            showBlockRefundFundsCanceledContractDgo: function (item) {
                return this.isAccrualType(item) && this.isRefundFundsCanceledContractDgo(item);
            },
            showBlockRefundFundsCanceledContractAutolawyer: function (item) {
                return this.isAccrualType(item) && this.isRefundFundsCanceledContractAutolawyer(item);
            },
            showBlockRefundFundsThroughPaymentSystem: function (item) {
                return this.isAccrualType(item) && this.isRefundFundsThroughPaymentSystem(item);
            },
            showRowBlockWritingOffCommissionDgo: function (item) {
                return this.isWriteOffType(item) && this.isWritingOffCommissionDgo(item);
            },
            showRowBlockWritingOffCommissionAutolawyer: function (item) {
                return this.isWriteOffType(item) && this.isWritingOffCommissionAutolawyer(item);
            },
            showRowBlockWritingOffCommissionOsago: function (item) {
                return this.isWriteOffType(item) && this.isWritingOffCommissionOsago(item);
            },
            showRowBlockWritingOffCommissionManual: function (item) {
                return this.isWriteOffType(item) && this.isWritingOffCommissionManual(item);
            },
            showRowBlockWritingOffCommissionGreenCard: function (item) {
                return this.isWriteOffType(item) && this.isWritingOffCommissionGreenCard(item);
            },
            showRowBlockWritingOffCommissionTourism: function (item) {
                return this.isWriteOffType(item) && this.isWritingOffCommissionTourism(item);
            },
            showRowBlockWritingOffReferralCommission: function (item) {
                return this.isWriteOffType(item) && this.isWritingOffReferralCommission(item)
            },
            showBlockWritingOffCommissionTestOsago: function (item) {
                return this.isWriteOffType(item) && this.isWritingOffCommissionTestOsago(item);
            },
            showBlockWritingOffCommissionTestDgo: function (item) {
                return this.isWriteOffType(item) && this.isWritingOffCommissionTestDgo(item)
            },
            showBlockRefundFundsTestContract: function (item) {
                return this.isAccrualType(item) && this.isRefundFundsTestContract(item);
            },
            showBlockRefundFundsTestContractDgo: function (item) {
                return this.isAccrualType(item) && this.isRefundFundsTestContractDgo(item);
            },
            showBlockPaymentInsuranceByLegalEntity: function (item) {
                return this.isReplenishmentType(item) && this.isPaymentInsuranceByLegalEntityType(item);
            },
            showBlockMonobankPayParts: function (item) {
                return this.isReplenishmentType(item) && item.subtype === this.accountCashHistoryInfo.subtype['payment_of_insurance_by_monobank_pay_parts'];
            },
        }
    }
</script>
